import Student from './Student'

export enum PackType {
  PERIODIC = 'PERIODIC',
  PAY_PER_CLASS = 'PAY_PER_CLASS'
}

export default interface Pack {
  id: string
  academyId: string
  name: string
  type: PackType
  value: number
  price: number
  students: Student[]
}
