import React from 'react'
import Pack from '../../types/Pack'
import PackCard from '../../components/packs/PackCard'
import _ from 'lodash'

const StudentPacks: React.FC<{ packs: Pack[] }> = ({ packs }) => {
  return (
    <div className="p-4">
      {_.size(packs) ? (
        packs.map(p => <PackCard key={`pack-${p.id}`} pack={p} />)
      ) : (
        <p className="text-center">No Packs</p>
      )}
    </div>
  )
}

export default StudentPacks
