import React from 'react'

interface Props {
  className?: string
}

const Content: React.FC<Props> = props => {
  return (
    <div className={`flex flex-col h-full ${props.className ? props.className : ''}`}>
      {React.Children.toArray(props.children)}
    </div>
  )
}

export const ScrollView: React.FC<Props> = props => {
  return (
    <div className={`flex-1 overflow-auto  ${props.className ? props.className : ''}`}>
      {React.Children.toArray(props.children)}
    </div>
  )
}

export default Content
