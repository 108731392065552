import React from 'react'

interface Props {
  spacing?: number
}

const HorizontalScrollView: React.FC<Props> = ({ children, spacing }) => {
  return (
    <div className="flex max-w-full overflow-auto no-scrollbar">
      {React.Children.map(children, child => (
        <div className={`ml-${spacing} my-${spacing}`}>{child}</div>
      ))}
      <div className={`min-w-${spacing}`}></div>
    </div>
  )
}

HorizontalScrollView.defaultProps = {
  spacing: 4
}

export default HorizontalScrollView
