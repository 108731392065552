import React from 'react'
import * as _ from './Icon'
import Icon from '../../../types/Icon'

export const components = {
  'arrow-left': _.ArrowLeftIcon,
  'arrow-right': _.ArrowRightIcon,
  coaches: _.CoachesIcon,
  home: _.HomeIcon,
  students: _.StudentsIcon,
  class: _.ClassIcon,
  user: _.UserIcon,
  more: _.MoreIcon,
  logout: _.LogoutIcon,
  'bundle-periodic': _.BundlePeriodicIcon,
  'bundle-pack': _.BundlePackIcon,
  menu: _.MenuIcon,
  billing: _.BillingIcon
}

export const CAIcon: React.FC<Icon> = props => {
  // eslint-disable-next-line prefer-const
  let { height, onClick, color } = props
  const type = props.type ? props.type : 'arrow-right'
  const SpecificIcon = components[type]
  if (!color) {
    color = '#1E2C5A'
  }
  return <SpecificIcon height={height} onClick={onClick} color={color} {...props} />
}
