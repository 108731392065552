import React from 'react'
import CAButton from '../../components/Chessadmin/Button/index'
import { Input, Form } from 'antd'
import { createUser } from '../../store/thunks'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axiosClient from '../../config/axios'

const Signup = () => {
  const history = useHistory()
  const [stepper, setStepper] = React.useState(0)
  const [usernameStatus, setUsernameStatus] = React.useState()
  const [emailStatus, setEmailStatus] = React.useState()
  const handlePageChange = (page: number) => {
    setStepper(page)
  }
  const dispatch = useDispatch()
  const debounceSingleFieldValidation = React.useCallback(
    _.debounce(async (value: any, type: string) => {
      let payload = {}
      if (type === 'username') {
        payload = {
          username: value
        }
      } else if (type === 'email') {
        payload = {
          email: value
        }
      }
      const response = await axiosClient.post(
        `https://api-core.chesslang.com/api/v2/identity/account/${type}-exists`,
        payload
      )
      if (response.data) {
        const status = !response.data.exists ? 'success' : 'error'
        if (type === 'username') {
          setUsernameStatus(status)
        } else if (type === 'email') {
          setEmailStatus(status)
        }
      }
    }, 750),
    []
  )
  const onUsernameChange = async (value: any) => {
    setUsernameStatus('validating')
    debounceSingleFieldValidation(value.target.value, 'username')
  }
  const onEmailChange = async (value: any) => {
    setEmailStatus('validating')
    debounceSingleFieldValidation(value.target.value, 'email')
  }
  const [formOnboard] = Form.useForm()
  const onFinish = (values: any) => {
    const payload = {
      phone: '',
      gender: 'M',
      dateOfBirth: 0,
      complete: false,
      error: '',
      ...values,
      phoneNumber: values.number
    }
    dispatch(createUser(payload))
  }
  if (stepper === 0) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col">
          <p className="text-3xl leading-none px-8 pt-16 text-primary-blue">Welcome</p>
          <Form form={formOnboard} onFinish={onFinish} className="mx-8">
            <Form.Item
              label="Username"
              name="username"
              hasFeedback
              validateStatus={usernameStatus}
              rules={[
                {
                  required: true,
                  message: 'Please enter a username'
                }
              ]}
            >
              <Input placeholder="Username" onChange={onUsernameChange} />
            </Form.Item>
            <Form.Item label="First name" name="firstname">
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item label="Last name" name="lastname">
              <Input placeholder="Last name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              hasFeedback
              validateStatus={emailStatus}
              rules={[
                {
                  required: true,
                  message: 'Please enter an email'
                }
              ]}
            >
              <Input placeholder="Email" type="email" onChange={onEmailChange} />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input placeholder="Password" type="password" />
            </Form.Item>
            <Form.Item label="Number" name="number">
              <Input placeholder="Number" />
            </Form.Item>
            <Form.Item>
              {/* <Button type="primary" htmlType="submit" block>
                Submit
              </Button> */}
              <div className="mt-4">
                <CAButton type="primary" text="Save" onClick={() => formOnboard.submit} />
              </div>
              <div className="mt-4">
                <CAButton type="transparent" text="Cancel" onClick={() => history.push('/onboard')} />
              </div>
            </Form.Item>
          </Form>
        </div>
        {/* <div className="flex flex-col">
          <div className="px-8 items-end">
            <p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>
            <Button type="primary" text="Save" onClick={onFinish} />
          </div>
          <div className="px-8 mt-2 mb-4">
            <p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>
            <Button type="transparent" text="Cancel" onClick={() => handlePageChange(0)} />
          </div>
        </div> */}
      </div>
    )
  } else if (stepper === 1) {
    return (
      <div className="flex flex-col h-screen justify-between">
        <div className="flex flex-col background-img-one">
          <p className="text-3xl text-center pt-56 text-primary-blue">Congrats !</p>
        </div>
        <div className="flex flex-col">
          <p className="text-xl px-8 text-primary-blue">Jane Doe</p>
          <p className="text-base px-8 text-primary-blue">Welcome Aboard, Your account is successfully created.</p>
          <p className="text-base px-8 text-primary-blue">Next step is to create an amazing profile.</p>
        </div>
        {/* <div className="flex flex-col ">
          <div className="px-8 items-end">
            <p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>
            <Button type="primary" text="Create an academy" onClick={() => handlePageChange(3)} />
          </div>
          <div className="px-8 mt-2 mb-4">
            <p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>
            <Button type="transparent" text="Join academy" onClick={() => handlePageChange(1)} />
          </div>
        </div> */}
      </div>
    )
  } else if (stepper === 2) {
    return (
      <div className="flex flex-col h-screen justify-between">
        <div className="flex flex-col">
          <p className="text-3xl px-8 pt-16 text-primary-blue">
            Academy <span className="text-base text-primary-light-blue">/ info </span>
          </p>
          <Form className="mx-8">
            <Form.Item label="First name">
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item label="Last name">
              <Input placeholder="Last name" />
            </Form.Item>
            <Form.Item label="Email">
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item label="Number">
              <Input placeholder="Number" />
            </Form.Item>
          </Form>
        </div>
        {/* <div className="flex flex-col ">
          <div className="px-8 mb-16 items-end">
            <p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>
            <Button type="primary" text="Next" onClick={() => handlePageChange(4)} />
          </div>
        </div> */}
      </div>
    )
  } else if (stepper === 3) {
    return (
      <div className="flex flex-col h-screen justify-between">
        <div className="flex flex-col">
          <p className="text-3xl px-8 pt-16 text-primary-blue">
            Academy <span className="text-base text-primary-light-blue">/ who are you </span>
          </p>
          <Form className="mx-8">
            <Form.Item label="First name">
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item label="Last name">
              <Input placeholder="Last name" />
            </Form.Item>
            <Form.Item label="Email">
              <Input placeholder="Email" />
            </Form.Item>
          </Form>
        </div>
        {/* <div className="flex flex-col ">
          <div className="px-8 mb-2 items-end">
            <p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>
            <Button type="primary" text="Next" onClick={() => handlePageChange(5)} />
          </div>
          <div className="px-8 mb-4 items-end">
            <p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>
            <Button type="transparent" text="Go Back" onClick={() => handlePageChange(3)} />
          </div>
        </div> */}
      </div>
    )
  } else {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    )
  }
}

export default Signup
