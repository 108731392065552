import React, { useState, useEffect, useCallback } from 'react'
import { Modal, Button, Input, Checkbox } from 'antd'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store/store'
import Student from '../../../types/Student'
import useSearchable from '../../../hooks/searchableHook'
import { Checkable } from '../../../types/Checkable'

interface Props {
  visible: boolean
  onCancel: () => void
  onOk: (studentIds: string[]) => void
  excludeStudentIds: string[]
  loading: boolean
}

const AllocateStudents: React.FC<Props> = props => {
  const students = useSelector((state: AppState) => state.students.data)

  const [checkableStudents, setCheckableStudents] = useState<Checkable<Student>[]>([])

  useEffect(() => {
    const newStudents = students
      .filter(s => !props.excludeStudentIds.includes(s.uuid))
      .map(s => {
        return {
          ...s,
          checked: false
        }
      })
    setCheckableStudents(newStudents)
  }, [students, props.excludeStudentIds])

  const searchableStudents = useSearchable(checkableStudents, s => [s.fullName, s.username])

  const handleOk = () => {
    props.onOk(checkableStudents.filter(s => s.checked).map(s => s.uuid))
  }

  const handleCheckChange = useCallback(
    (student: Checkable<Student>) => {
      setCheckableStudents(checkableStudents.map(s => (s.uuid === student.uuid ? student : s)))
    },
    [checkableStudents]
  )

  return (
    <Modal
      title="Allocate students"
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={handleOk}
      footer={[
        <Button key="back" onClick={props.onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={props.loading} onClick={handleOk}>
          Update
        </Button>
      ]}
    >
      <Input.Search placeholder="Search students" className="w-full mb-2" {...searchableStudents.input} />
      <div className="h-64 overflow-auto">
        {searchableStudents.data.map(s => (
          <StudentItem student={s} onChange={handleCheckChange} />
        ))}
      </div>
    </Modal>
  )
}

const StudentItem: React.FC<{
  student: Checkable<Student>
  onChange: (student: Checkable<Student>) => void
}> = ({ student, onChange }) => {
  const [checked, setChecked] = useState(student.checked)

  const handleCheckChange = useCallback(() => {
    const newChecked = !checked
    setChecked(newChecked)
    onChange({ ...student, checked: newChecked })
  }, [checked, onChange, student])

  return (
    <div className="p-2" onClick={handleCheckChange}>
      <Checkbox checked={student.checked} onChange={handleCheckChange} className="mr-2"></Checkbox>
      <span>{student.formattedName}</span>
    </div>
  )
}

export default AllocateStudents
