import React from 'react'
import './index.scss'
import { CAIcon } from '../Icon/CAIcon'
import { components } from '../Icon/CAIcon'
import Lottie from 'react-lottie'
import LoadingDropBlue from '../../../assets/animations/loading-drop-blue.json'
import LoadingDropWhite from '../../../assets/animations/loading-drop-white.json'

type ButtonProps = {
  type?: string
  text: string
  onClick: () => void
  icon?: keyof typeof components
  loading?: boolean
}

const CAButton: React.FC<ButtonProps> = props => {
  const { type, text, onClick, icon, loading } = props
  let className = 'btn'
  let color = '#1E2C5A'
  if (type === 'primary') {
    className += ' bg-primary-blue text-primary-light-blue'
    color = '#E2EAF6'
  } else if (type === 'transparent') {
    className += ' bg-white text-primary-blue'
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: !color ? LoadingDropBlue : LoadingDropWhite,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  if (loading) {
    return (
      <button className={className}>
        <Lottie options={defaultOptions} height={50} width={50} />
      </button>
    )
  } else {
    return (
      <button className={className} onClick={onClick}>
        {text}
        {icon ? (
          <span className="pl-2">
            <CAIcon type={icon} height="15px" color={color} button={true} />
          </span>
        ) : (
          <span />
        )}
      </button>
    )
  }
}

export default CAButton
