import React, { useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState, useThunkDispatch } from '../../store/store'
import Group from '../../types/Group'
import Content, { ScrollView } from '../../components/Content'
import { ActionBar } from '../../components/ActionBar'
import StudentList from '../../components/StudentList'
import { Button, message } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import AllocateStudents from '../../components/coaches/modals/AllocateStudents'
import { addStudentsToGroup, deleteGroupStudent } from '../../store/thunks'
import Student from '../../types/Student'

interface Params {
  groupId: string
}

const GroupStudents: React.FC = () => {
  const params = useParams<Params>()
  const thunkDispatch = useThunkDispatch()

  const groups = useSelector((state: AppState) => state.groups.data)

  const group = groups.find((g: Group) => g.id === params.groupId) as Group

  const groupStudents = group?.students || []

  const studentIds = useMemo(() => groupStudents.map(s => s.uuid), [groupStudents])

  const [modalVisible, setModalVisible] = useState(false)
  const [inFlight, setInFlight] = useState(false)

  const showModal = useCallback(() => {
    setModalVisible(true)
  }, [])

  const hideModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  const handleOk = useCallback(
    async (studentIds: string[]) => {
      try {
        setInFlight(true)
        await thunkDispatch(addStudentsToGroup({ groupId: group.id, studentIds }))
        message.success('Students added successfully!')
        setModalVisible(false)
      } catch {
        message.error('Error occurred')
      } finally {
        setInFlight(false)
      }
    },
    [group, thunkDispatch]
  )

  const handleDelete = useCallback(
    async (student: Student) => {
      try {
        await thunkDispatch(deleteGroupStudent({ groupId: group.id, studentId: student.uuid }))
        message.success('Student deleted successfully!')
      } catch {
        message.error('Error occurred')
      }
    },
    [group, thunkDispatch]
  )

  return (
    <Content>
      <ActionBar title={group?.name} />
      <ScrollView>
        <StudentList
          showDeleteOption={true}
          onDelete={handleDelete}
          data={groupStudents}
          actions={[<Button onClick={showModal} className="ml-1" icon={<PlusCircleOutlined />} />]}
        />
      </ScrollView>
      <AllocateStudents
        visible={modalVisible}
        loading={inFlight}
        excludeStudentIds={studentIds}
        onOk={handleOk}
        onCancel={hideModal}
      />
    </Content>
  )
}

export default GroupStudents
