import * as React from 'react'

function SvgArrowRight(props) {
  return (
    <svg id="arrow-right_svg__Layer_1" x={0} y={0} viewBox="0 0 123 231" xmlSpace="preserve" {...props}>
      <style />
      <path d="M7.5 0c1.9 0 3.8.7 5.3 2.2l108 108c2.9 2.9 2.9 7.7 0 10.6l-108 108c-2.9 2.9-7.7 2.9-10.6 0-2.9-2.9-2.9-7.7 0-10.6l102.7-102.7L2.2 12.8C-.7 9.9-.7 5.1 2.2 2.2 3.7.7 5.6 0 7.5 0z" />
    </svg>
  )
}

export default SvgArrowRight
