import React from 'react'
import TabLayout, { Tab } from '../../components/TabLayout'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/store'
import Student from '../../types/Student'
import Content, { ScrollView } from '../../components/Content'
import { ActionBar } from '../../components/ActionBar'
import StudentBilling from './StudentBilling'
import StudentClasses from './StudentClasses'
import StudentPacks from './StudentPacks'
import Pack from '../../types/Pack'

const StudentView: React.FC = () => {
  const { studentId } = useParams<{ studentId: string }>()
  const match = useRouteMatch()

  const student = useSelector((state: AppState) => state.students.data.find(s => s.uuid === studentId)) as Student

  const invoices = useSelector((state: AppState) => state.invoice.data.filter(i => i.studentId === studentId))

  const packs = useSelector((state: AppState) => state.pack.data)

  const classes = useSelector((state: AppState) =>
    state.classLog.data.filter(({ students }) => {
      return students != null && students.map(s => s.studentId).includes(studentId)
    })
  )

  const classWithPacks = classes.map(c => {
    return {
      ...c,
      pack: packs.find(p => c.packId === p.id)
    }
  })

  const studentPacks = classWithPacks.map(cwp => cwp.pack).filter(p => p !== undefined) as Pack[]

  return (
    <Content>
      <ActionBar title={student?.fullName as string} subtitle={`User ID: ${student?.username}`} />
      <ScrollView>
        <TabLayout>
          <Tab title="Billing" path={`${match.url}/billing`}>
            <StudentBilling invoices={invoices} />
          </Tab>
          <Tab title="Classes" path={`${match.url}/classes`}>
            <StudentClasses classes={classWithPacks} />
          </Tab>
          <Tab title="Packs" path={`${match.url}/packs`}>
            <StudentPacks packs={studentPacks} />
          </Tab>
        </TabLayout>
      </ScrollView>
    </Content>
  )
}

export default StudentView
