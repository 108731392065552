import React from 'react'
import { CalendarOutlined, UserOutlined } from '@ant-design/icons'
import Class from '../../types/Class'
import moment from 'moment'
import { fullUserName } from '../../utils/FormattingUtils'
import ClassStudent from '../../types/ClassStudent'

interface Props {
  classObj: Class
}

const ClassCard: React.FC<Props> = props => {
  const { happenedAt, duration } = props.classObj
  const { username } = props.classObj.coach

  const students: ClassStudent[] = props.classObj.students || []

  const date = moment(happenedAt).format('dddd, Do MMMM YYYY')
  const startTime = moment(happenedAt).format('h:mm A')
  const endTime = moment(happenedAt)
    .add(duration, 'm')
    .format('h:mm A')

  return (
    <div className="bg-gradient-primary text-white mb-4 p-4 rounded-lg">
      <div className="flex items-center mb-6">
        <UserOutlined />
        <span className="ml-2 p-title">
          {fullUserName(props.classObj.coach)} <span className="text"> / {username}</span>
        </span>
      </div>

      <div className="flex justify-between items-end">
        <div className="flex">
          <CalendarOutlined className="pt-1" />
          <div className="ml-2 text">
            <div>{date}</div>
            <div>
              {startTime} - {endTime}
            </div>
          </div>
        </div>
        <div className="text-right">
          <div>
            <span className="text-md font-bold">{students.filter(s => s.attended).length}</span>{' '}
            <span className="text-sm">/ {students.length}</span>
          </div>
          <div className="text-xs">students</div>
        </div>
      </div>
    </div>
  )
}

export default ClassCard
