import * as React from 'react'

function SvgLogout(props) {
  return (
    <svg id="logout_svg__Layer_1" x={0} y={0} viewBox="0 0 234 231" xmlSpace="preserve" {...props}>
      <style />
      <path d="M160.5 216H15V15h145.5c4.1 0 7.5-3.4 7.5-7.5S164.6 0 160.5 0H7.5C3.4 0 0 3.4 0 7.5v216c0 4.1 3.4 7.5 7.5 7.5h153c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5z" />
      <path d="M231.8 109.5l-54.7-54.7c-2.9-2.9-7.7-2.9-10.6 0-2.9 2.9-2.9 7.7 0 10.6l42.7 42.7H96.8c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5h110.8l-41.2 41.2c-2.9 2.9-2.9 7.7 0 10.6 1.5 1.5 3.4 2.2 5.3 2.2s3.8-.7 5.3-2.2l54.7-54.7c3-3.1 3-7.8.1-10.7z" />
    </svg>
  )
}

export default SvgLogout
