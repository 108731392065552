import { createSlice } from '@reduxjs/toolkit'
import User from '../types/User'

const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    isLoggedIn: false,
    user: {
      iss: '',
      exp: null,
      uuid: '',
      username: '',
      firstname: '',
      lastname: '',
      role: null,
      // eslint-disable-next-line @typescript-eslint/camelcase
      game_collection_uuid: null,
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_tournament_on: null
    },
    role: '',
    moreInfo: {
      email: '',
      phoneNumber: '',
      firstname: '',
      lastname: '',
      gender: ''
    }
  },
  reducers: {
    login: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload
      }
    },
    setMoreInfo: (state, action) => {
      console.log('asdaksdaskdgaskjdgsakjdhkja')
      console.log(action)
      return {
        ...state,
        moreInfo: action.payload
      }
    },
    logout: state => {
      return {
        ...state,
        isLoggedIn: false,
        user: {
          iss: '',
          exp: null,
          uuid: '',
          username: '',
          firstname: '',
          lastname: '',
          role: null,
          // eslint-disable-next-line @typescript-eslint/camelcase
          game_collection_uuid: null,
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_tournament_on: null
        },
        moreInfo: {
          email: '',
          phoneNumber: '',
          firstname: '',
          lastname: '',
          gender: ''
        }
      }
    },
    setRole: (state, action) => {
      return {
        ...state,
        role: action.payload
      }
    }
  }
})

export default userSlice
