import React from 'react'

interface ToolbarProps {
  actions: JSX.Element[]
}

export const Toolbar: React.FC<ToolbarProps> = props => {
  return (
    <div className="px-4 pt-4 pb-2 flex justify-between">
      <div className="flex-1">{props.children}</div>
      <div>{props.actions}</div>
    </div>
  )
}
