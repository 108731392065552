import React, { useState, useEffect } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Popover, Radio, Button, Divider } from 'antd'
import _ from 'lodash'

export type SortOrder = 'asc' | 'desc' | boolean

interface Props {
  options: object
  onChange: (sortKey: string, sortOrder: SortOrder) => void
  defaultSortOrder?: SortOrder
}

const SortButton: React.FC<Props> = ({ options, onChange, defaultSortOrder }) => {
  const pairs = _.toPairs(options)

  const [sortBy, setSortBy] = useState(pairs[0][0])
  const [sortOrder, setSortOrder] = useState<SortOrder>(defaultSortOrder || 'asc')

  useEffect(() => {
    onChange(sortBy, sortOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder])

  return (
    <Popover
      title="Sort"
      placement="topRight"
      content={
        <>
          <Radio.Group onChange={e => setSortBy(e.target.value)} value={sortBy}>
            {pairs.map(entry => (
              <Radio className="block" key={`sort-option-${entry[0]}`} value={entry[0]}>
                {entry[1]}
              </Radio>
            ))}
          </Radio.Group>
          <Divider className="my-2" />
          <Radio.Group onChange={e => setSortOrder(e.target.value)} value={sortOrder}>
            <Radio className="block" value="asc">
              Ascending
            </Radio>
            <Radio className="block" value="desc">
              Descending
            </Radio>
          </Radio.Group>
        </>
      }
      trigger="click"
    >
      <Button
        className="ml-1"
        icon={<LegacyIcon type={sortOrder === 'asc' ? 'sort-ascending' : 'sort-descending'} />}
      />
    </Popover>
  )
}

export default SortButton
