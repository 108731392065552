import * as React from 'react'

function Menu(props) {
  return (
    <svg id="menu_svg__Layer_1" x={0} y={0} viewBox="0 0 234 123" xmlSpace="preserve" {...props}>
      <style />
      <path d="M227 15H7c-3.9 0-7-3.4-7-7.5S3.2 0 7 0h220c3.9 0 7 3.4 7 7.5s-3.2 7.5-7 7.5zM176.2 69H7c-3.9 0-7-3.4-7-7.5S3.2 54 7 54h169.2c3.9 0 7 3.4 7 7.5s-3.1 7.5-7 7.5zM227 123H7c-3.9 0-7-3.4-7-7.5s3.2-7.5 7-7.5h220c3.9 0 7 3.4 7 7.5s-3.2 7.5-7 7.5z" />
    </svg>
  )
}

export default Menu
