import * as React from 'react'

function SvgBundlePeriodic(props) {
  return (
    <svg id="bundle-periodic_svg__Layer_1" x={0} y={0} viewBox="0 0 272.9 114" xmlSpace="preserve" {...props}>
      <style />
      <path d="M272 91l-16.5-30.6c-.9-1.7-2.5-3-4.4-3.6l-.3-.1c-1.9-.6-4-.4-5.7.6l-30.6 16.5c-3.6 2-5 6.5-3 10.2 2 3.6 6.5 5 10.2 3l13.5-7.3c-1.4 10.8-10.7 19.2-22 19.2H56.4c-12.2 0-22.2-9.9-22.2-22.2v-4.9c0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5v4.9c0 20.5 16.7 37.2 37.2 37.2h156.7c18.8 0 34.4-14.1 36.8-32.2l8.9 16.4c1.4 2.5 3.9 3.9 6.6 3.9 1.2 0 2.4-.3 3.6-.9 3.6-1.9 4.9-6.4 3-10.1zM22.2 52.1c.7.2 1.4.3 2.1.3 1.2 0 2.5-.3 3.6-.9L58.5 35c3.6-2 5-6.5 3-10.2-2-3.6-6.5-5-10.2-3l-16.1 8.7C38 21.5 46.4 15 56.3 15H213c12.2 0 22.2 9.9 22.2 22.2 0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5C250.2 16.7 233.5 0 213 0H56.4c-16 0-29.7 10.2-34.9 24.4l-7.4-13.7c-2-3.6-6.5-5-10.2-3-3.6 2-5 6.5-3 10.2l16.5 30.6c.9 1.8 2.6 3.1 4.5 3.6h.3z" />
    </svg>
  )
}

export default SvgBundlePeriodic
