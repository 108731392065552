import React, { useCallback } from 'react'
import { ActionBar } from '../../components/ActionBar'
import { Card, Radio, Button } from 'antd'
import Content, { ScrollView } from '../../components/Content'
import { useSelector, useDispatch } from 'react-redux'
import ClassStudent from '../../types/ClassStudent'
import { useParams, useHistory } from 'react-router-dom'
import { AppState } from '../../store/store'
import { updateAttendance } from '../../store/thunks'
import { CopyOutlined } from '@ant-design/icons'
import Student from '../../types/Student'

type StudentWithClass = { classStudent: ClassStudent; student: Student }

const TakeAttendance: React.FC = () => {
  const { classId } = useParams<{ classId: string }>()
  const clazz = useSelector((state: AppState) => state.classLog.data.find(c => c.id === classId))
  const students = useSelector((state: AppState) => state.students.data)
  const dispatch = useDispatch()
  const history = useHistory()

  const classStudents = clazz?.students || []

  const studentsWithClass = classStudents.map<StudentWithClass>(cs => {
    return {
      classStudent: cs,
      student: students.find(s => s.uuid === cs.studentId) as Student
    }
  })

  const handleOnChange = (s: StudentWithClass, a: boolean) => {
    dispatch(
      updateAttendance({
        id: s.classStudent.id,
        attended: a
      })
    )
  }

  const handleOnClone = useCallback(() => {
    history.push({
      pathname: '/classes/create',
      search: `?cloneFrom=${classId}`
    })
  }, [history, classId])

  return (
    <Content>
      <ActionBar title="Class">
        <Button icon={<CopyOutlined />} onClick={handleOnClone}>
          Clone
        </Button>
      </ActionBar>
      <ScrollView>
        <div className="m-4">
          <div className="text-lg mb-4">{clazz?.coach.fullName}</div>
          <span className="text-xl">Description</span>
          <p>{clazz?.description || 'No description'}</p>
        </div>

        {studentsWithClass.map(s => (
          <AttendanceCard key={`class-student-${s.classStudent.id}`} student={s} onChange={handleOnChange} />
        ))}
      </ScrollView>
    </Content>
  )
}

const AttendanceCard: React.FC<{
  student: StudentWithClass
  onChange: (student: StudentWithClass, attended: boolean) => void
}> = ({ student, onChange }) => (
  <Card title={student.student?.fullName} className="mb-4">
    <Radio.Group
      className="flex"
      value={student.classStudent.attended}
      onChange={e => {
        onChange(student, e.target.value)
      }}
    >
      <Radio.Button className="text-center flex-1" value={true}>
        Present
      </Radio.Button>
      <Radio.Button className="text-center flex-1" value={false}>
        Absent
      </Radio.Button>
      <Radio.Button className="text-center flex-1" value="leave">
        Leave
      </Radio.Button>
    </Radio.Group>
  </Card>
)

export default TakeAttendance
