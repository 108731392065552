import React, { useCallback } from 'react'
import { UserOutlined, DeleteOutlined } from '@ant-design/icons'
import Group from '../../types/Group'
import { Tag, Divider } from 'antd'

interface Props {
  group: Group
  onDelete: (student: Group) => void
}

const GroupCard: React.FC<Props> = props => {
  const { name, studentCount, isSchool } = props.group

  const handleOnDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      props.onDelete(props.group)
    },
    [props]
  )

  return (
    <div className="flex items-center bg-gradient-primary text-white mb-4 p-4 rounded-lg">
      {/* <div>
        <Icon type="edit" className="mx-1 text-xl" onClick={() => handleGroupEdit(group)} />
        <Icon type="copy" className="mx-1 text-xl" />
        <Icon type="delete" className="mx-1 text-xl" />
      </div> */}
      <div className="flex items-center">
        <UserOutlined className="pt-1 text-xl" />
        <div className="ml-2 text-lg ">{name}</div>
        {isSchool && (
          <Tag color="blue" className="text-xs ml-1">
            School
          </Tag>
        )}
      </div>
      <div className="text-center ml-auto">
        <div>{studentCount}</div>
        <div className="text-xs">students</div>
      </div>
      <Divider type="vertical" className="ml-2 h-6 self-center" />
      <DeleteOutlined className="text-lg self-center" onClick={handleOnDelete} />
    </div>
  )
}

export default GroupCard
