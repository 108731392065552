import React from 'react'
import { Menu } from 'antd'
import { useHistory, Route, Switch } from 'react-router-dom'
import Content, { ScrollView } from './Content'

interface TabProps {
  title: string
  path: string
}

export const Tab: React.FC<TabProps> = ({ path, children }) => {
  return <Route path={path}>{children}</Route>
}

const TabLayout: React.FC = ({ children }) => {
  const history = useHistory()

  const tabKey = (idfr: string) => `tab-${idfr}`

  let currentTab = ''

  const tabs = React.Children.toArray(children) as JSX.Element[]

  tabs.forEach(({ props: { title, path } }) => {
    if (history.location.pathname === path) {
      currentTab = tabKey(title)
    }
  })

  return (
    <Content>
      <Menu
        className="flex"
        mode="horizontal"
        defaultSelectedKeys={[currentTab]}
        onClick={({ item }) => history.replace(item.node.dataset.path)}
      >
        {tabs.map(({ props: { title, path } }) => (
          <Menu.Item className="flex-1 text-center" key={tabKey(title)} data-path={path}>
            {title}
          </Menu.Item>
        ))}
      </Menu>
      <ScrollView>
        <Switch>{children}</Switch>
      </ScrollView>
    </Content>
  )
}
export default TabLayout
