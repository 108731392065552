import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, useThunkDispatch } from '../../store/store'
import AllocateStudents from '../../components/coaches/modals/AllocateStudents'
import { ActionBar } from '../../components/ActionBar'
import Content, { ScrollView } from '../../components/Content'
import {
  CloseCircleOutlined,
  CopyOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  ScissorOutlined,
  SelectOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { Button, Popover, Menu, message } from 'antd'
import StudentList from '../../components/StudentList'
import { allocateStudents, deleteCoachStudent } from '../../store/thunks'
import ActionState from '../../types/ActionState'
import allocateStudentSlice from '../../store/allocateStudentSlice'

interface Params {
  coachId: string
}

const CoachStudents: React.FC = () => {
  const dispatch = useDispatch()
  const thunkDispacth = useThunkDispatch()
  const params = useParams<Params>()

  const [multiselect, setMultiselect] = useState(false)
  const [allocateStudentModal, setAllocateStudentModal] = useState(false)

  const coach = useSelector((state: AppState) => state.coach.data.find(c => c.uuid === params.coachId))

  const students = coach?.students || []

  const actionState = useSelector((state: AppState) => state.allocateStudent.actionState)
  const loading = actionState === ActionState.LOADING

  useEffect(() => {
    if (!loading) {
      if (actionState === ActionState.SUCCESS) {
        message.success('Students allocate successfully')
        setAllocateStudentModal(false)
      } else if (actionState === ActionState.ERROR) {
        message.error('Oops! Something went wrong')
      }
      dispatch(allocateStudentSlice.actions.actionState(ActionState.INITIAL))
    }
  }, [actionState, loading, dispatch])

  const handleOnOk = (studentIds: string[]) => {
    dispatch(allocateStudents(coach?.uuid as string, studentIds))
  }

  const actions = [
    <Button
      key="coach-students-multiselect"
      icon={<SelectOutlined />}
      className="ml-1"
      onClick={() => setMultiselect(true)}
    />
  ]

  const multeselectOptions = (
    <Menu>
      <Menu.Item>
        <DeleteOutlined />
        Remove
      </Menu.Item>
      <Menu.Item>
        <ScissorOutlined />
        Move
      </Menu.Item>
      <Menu.Item>
        <CopyOutlined />
        Copy
      </Menu.Item>
      <Menu.Item onClick={() => setMultiselect(false)}>
        <CloseCircleOutlined />
        Cancel
      </Menu.Item>
    </Menu>
  )

  const multiselectActions = [
    <Popover
      key="coach-students-mutliselect-options"
      placement="bottomRight"
      content={multeselectOptions}
      title="Options"
      trigger="click"
    >
      <Button icon={<MoreOutlined />} className="ml-1" />
    </Popover>
  ]

  const handleOnDelete = useCallback(
    async student => {
      try {
        await thunkDispacth(deleteCoachStudent({ studentId: student.uuid, coachId: coach?.uuid as string }))
        message.success('Student delete successfully')
      } catch (reason) {
        message.error('Something went wrong while delete student.')
      }
    },
    [coach, thunkDispacth]
  )

  return (
    <Content>
      <ActionBar title={`${coach?.fullName} (${coach?.username})`}>
        <Button key="coach-students-allocate" className="mr-1" onClick={() => setAllocateStudentModal(true)}>
          Add Students
          <PlusCircleOutlined />
        </Button>
      </ActionBar>
      <ScrollView>
        <StudentList
          data={students}
          actions={multiselect ? multiselectActions : actions}
          checkable={multiselect}
          onCheckListChange={console.log}
          showDeleteOption={true}
          onDelete={handleOnDelete}
        />
      </ScrollView>
      <AllocateStudents
        visible={allocateStudentModal}
        onCancel={() => setAllocateStudentModal(false)}
        excludeStudentIds={students.map(s => s.uuid)}
        onOk={handleOnOk}
        loading={loading}
      />
    </Content>
  )
}

export default CoachStudents
