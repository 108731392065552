import React, { useState, useCallback } from 'react'
import { Input, Button, Form, message } from 'antd'
import BottomSheet from '../BottomSheet'
import { useThunkDispatch } from '../../store/store'
import { createStudent } from '../../store/thunks'
import { Rule } from 'antd/lib/form'
import axiosClient from '../../config/axios'

interface Props {
  visible: boolean
  onCancel: () => void
}

const CreateStudentSheet: React.FC<Props> = props => {
  const thunkDispatch = useThunkDispatch()
  const [inFlight, setInFlight] = useState(false)

  const handleOnFinish = useCallback(
    async values => {
      setInFlight(true)
      try {
        await thunkDispatch(
          createStudent({
            ...values,
            dateOfBirth: 0,
            gender: 'M',
            phoneNumber: '',
            email: values.email || ''
          })
        )
        message.success('Student added successfully')
        props.onCancel()
      } catch {
        message.error('Error occurred while adding student')
      } finally {
        setInFlight(false)
      }
    },
    [props, thunkDispatch]
  )

  const unique: (field: string) => Rule = (field: string) => () => ({
    async validator(rule, value) {
      const error = `${field} already taken`

      try {
        const response = await axiosClient.post(
          `https://api-core.chesslang.com/api/v2/identity/account/${field}-exists`,
          {
            [field]: value
          }
        )

        if (!response.data.exists) {
          return Promise.resolve()
        }
      } catch {
        return Promise.resolve()
      }
      return Promise.reject(error)
    }
  })

  return (
    <BottomSheet value={props.visible} onChange={props.onCancel}>
      <Form onFinish={handleOnFinish} className="p-4">
        <Form.Item rules={[{ required: true }]} label="First name" name="firstname">
          <Input placeholder="First name" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="Last name" name="lastname">
          <Input placeholder="Last name" />
        </Form.Item>
        <Form.Item rules={[{ required: true }, unique('username')]} label="Username" name="username">
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true }, unique('email')]}>
          <Input placeholder="Email (optional)" />
        </Form.Item>
        <Button block type="primary" htmlType="submit" loading={inFlight}>
          Create
        </Button>
      </Form>
    </BottomSheet>
  )
}

export default CreateStudentSheet
