import React from 'react'
import HorizontalScrollView from '../../components/HorizontalScorllView'
import Invoice from '../../types/Invoice'
import moment from 'moment'
import _ from 'lodash'

const StudentBilling: React.FC<{ invoices: Invoice[] }> = ({ invoices }) => {
  return (
    <>
      <div className="mt-8 mx-4 mb-4 p-4 bg-gradient-primary rounded-lg text-white">
        <div>Outstanding Amount</div>
        <div className="mb-4 text-2xl">INR {_.sumBy(invoices, 'amount')}</div>
        {/* <div className="text-sm">Total Packages:</div>
        <div className="text-xs">{invoices.map(i => i.pack?.name).join(',')}</div> */}
      </div>
      {!_.isEmpty(invoices) && (
        <>
          <div className="text-lg px-4">Invoices</div>
          <HorizontalScrollView>
            {invoices.map(i => (
              <PrimaryCard key={`invoice-${i.id}`} invoice={i} />
            ))}
          </HorizontalScrollView>
        </>
      )}

      {/* <div className="text-lg px-4">Others</div>
      <HorizontalScrollView>
        <SecondaryCard />
      </HorizontalScrollView> */}
    </>
  )
}

const PrimaryCard: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const { pack } = invoice
  const startDate = moment(invoice.startDate).format('D MMM YYYY')
  return (
    <div className="p-4 w-56 bg-gradient-primary rounded-lg text-white">
      <div className="text-md">{pack?.name}</div>
      <div className="text-2xl">
        {invoice.consumed}/{pack?.value}
      </div>
      <div className="text-sm mb-4">Date - {startDate}</div>
      <div className="flex justify-between text-xs">
        <div>
          <div className="font-bold">Package</div>
          <div className="font-hairline">{pack?.price} Rs</div>
        </div>
        <div className="text-right">
          <div className="font-bold">Oustanding</div>
          <div className="font-hairline">1000 Rs</div>
        </div>
      </div>
    </div>
  )
}

const SecondaryCard = () => {
  return (
    <div className="p-4 w-56 bg-yellow-200 rounded-lg text-yellow-700">
      <div className="text-md">Student Onboarding</div>
      <div className="text-2xl">700 Rs</div>
      <div className="text-sm mb-8">Date - 1 Jan 2020</div>
    </div>
  )
}

export default StudentBilling
