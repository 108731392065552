import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Popover, Menu } from 'antd'
import { CAIcon } from './Chessadmin/Icon/CAIcon'
import { IconType } from '../types/Icon'

export interface NavLink {
  title: string
  path: string
  icon: IconType
}

interface Props {
  links: NavLink[]
}

const BottomNav: React.FC<Props> = ({ links }) => {
  const history = useHistory()
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false)

  const options = links.slice(0, 4)
  const moreOptions = links.slice(4)

  const goTo = (path: string) => () => {
    history.push(path)
    setMoreOptionsVisible(false)
  }

  const option = (title: string, icon: IconType, onClick: () => void) => (
    <div
      className="flex-1 flex flex-col justify-center px-2 items-center mt-1"
      onClick={onClick}
      key={`bottom-nav-option-${title}`}
    >
      <CAIcon type={icon} height="20px" />
      <span className="text-xs mt-2">{title}</span>
    </div>
  )

  return (
    <div className="p-2 flex justify-around bg-primary-navbar-blue text-primary-blue">
      {options.map(({ title, path, icon }) => option(title, icon, goTo(path)))}
      {moreOptions.length > 0 && option('More', 'more', () => setMoreOptionsVisible(!moreOptionsVisible))}
      <Popover
        placement="topRight"
        content={
          <Menu>
            {moreOptions.map(({ title, path, icon }) => (
              <Menu.Item className="flex items-center" key={`bottom-nav-more-option-${path}`} onClick={goTo(path)}>
                <CAIcon type={icon} height="15px" />
                <span className="ml-4">{title}</span>
              </Menu.Item>
            ))}
          </Menu>
        }
        title="More options"
        trigger="click"
        visible={moreOptionsVisible}
      ></Popover>
    </div>
  )
}

export default BottomNav
