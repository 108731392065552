import { useMemo } from 'react'
import useUserInput from './userInputHook'

const useSearchable = <T>(list: T[], keywords: (el: T) => string[]) => {
  const input = useUserInput('')

  const data = useMemo(() => {
    const regex = new RegExp(input.value, 'i')

    return list.filter(c => {
      return keywords(c).reduce<boolean>((accum, curr) => accum || regex.test(curr), false)
    })
  }, [input.value, list, keywords])

  return { input, data }
}

export default useSearchable
