import * as React from 'react'

function SvgArrowLeft(props) {
  return (
    <svg id="arrow-left_svg__Layer_1" x={0} y={0} viewBox="0 0 123 231" xmlSpace="preserve" {...props}>
      <style />
      <path d="M115.5 231c-1.9 0-3.8-.7-5.3-2.2l-108-108c-2.9-2.9-2.9-7.7 0-10.6l108-108c2.9-2.9 7.7-2.9 10.6 0 2.9 2.9 2.9 7.7 0 10.6L18.1 115.5l102.7 102.7c2.9 2.9 2.9 7.7 0 10.6-1.5 1.5-3.4 2.2-5.3 2.2z" />
    </svg>
  )
}

export default SvgArrowLeft
