import { createSlice } from '@reduxjs/toolkit'
import DataStatus from '../types/ActionState'

const updatePackSlice = createSlice({
  name: 'updatePack',
  initialState: {
    status: DataStatus.INITIAL
  },
  reducers: {
    status: (_, action) => {
      return {
        status: action.payload
      }
    }
  }
})

export default updatePackSlice
