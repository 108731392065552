import { createSlice } from '@reduxjs/toolkit'
import Class from '../types/Class'
import { userWithInfo } from '../utils/FormattingUtils'

const classLogSlice = createSlice({
  name: 'classLog',
  initialState: {
    data: [] as Class[],
    loading: false
  },
  reducers: {
    set: (state, action) => {
      return {
        ...state,
        data: action.payload.map((c: Class) => {
          return {
            ...c,
            coach: userWithInfo(c.coach)
          }
        })
      }
    },
    loading: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    },
    updateAttendance(state, action) {
      const data = state.data.map(c =>
        c.id === action.payload.classId
          ? {
              ...c,
              students: c.students.map(s => (s.studentId === action.payload.studentId ? action.payload : s))
            }
          : c
      )
      return {
        ...state,
        data
      }
    }
  }
})

export default classLogSlice
