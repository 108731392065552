import React, { useCallback, useMemo, useEffect } from 'react'
import { Checkbox, Form, Button, Slider } from 'antd'
import _ from 'lodash'
import { PackType } from '../../types/Pack'
import BottomSheet from '../../components/BottomSheet'

type Range = [number, number]

export interface Filter {
  packTypes: string[]
  touched: boolean
  classes: Range
  months: Range
  prices: Range
}

interface Props {
  visible: boolean
  onCancel: () => void
  value: Filter
  onChange: (filter: Filter) => void
}

const PackFilter: React.FC<Props> = props => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (!props.value.touched) {
      form.resetFields()
    }
  }, [form, props.value.touched])

  const packTypeOptions = useMemo(
    () =>
      _.toPairs(PackType).map(v => {
        return {
          label: v[0].replace(/[_]/g, '-'),
          value: v[1]
        }
      }),
    []
  )

  const handleOk = useCallback(() => {
    form.validateFields().then((values: any) => {
      props.onChange({ ...values, touched: true })
      props.onCancel()
    })
  }, [form, props])

  return (
    <BottomSheet value={props.visible} onChange={props.onCancel}>
      <Form form={form} initialValues={props.value} onFinish={handleOk} className="px-6 py-4">
        <Form.Item label="Pack Type" name="packTypes">
          <Checkbox.Group options={packTypeOptions} />
        </Form.Item>
        <Form.Item label="Periodic Value (Months)" name="months">
          <Slider min={1} max={12} range marks={{ 1: '0-1', 12: '12' }} />
        </Form.Item>
        <Form.Item label="Pay per class (Classes)" name="classes">
          <Slider min={1} max={120} range marks={{ 1: '0-1', 120: '120+' }} />
        </Form.Item>
        <Form.Item label="Amount" name="prices">
          <Slider min={500} max={15000} range step={50} marks={{ 500: '0-500', 15000: '15000+' }} />
        </Form.Item>
        <Button block type="primary" htmlType="submit">
          Apply
        </Button>
      </Form>
    </BottomSheet>
  )
}

export default PackFilter
