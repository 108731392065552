import { createSlice } from '@reduxjs/toolkit'
import DataStatus from '../types/ActionState'
import Invoice from '../types/Invoice'
import { userWithInfo } from '../utils/FormattingUtils'
import Student from '../types/Student'

const invoiceSlice = createSlice({
  name: 'invoiceSlice',
  initialState: {
    data: [] as Invoice[],
    status: DataStatus.INITIAL
  },
  reducers: {
    set: (state, action) => {
      return {
        ...state,
        data: action.payload.map((i: Invoice) => {
          return {
            ...i,
            student: userWithInfo(i.student as Student)
          }
        })
      }
    },
    status: (state, action) => {
      return {
        ...state,
        status: action.payload
      }
    }
  }
})

export default invoiceSlice
