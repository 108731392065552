import React, { useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import AddCoachSheet from '../../components/coaches/AddCoachSheet'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/store'
import { Link } from 'react-router-dom'
import Content, { ScrollView } from '../../components/Content'
import CoachCard from '../../components/coaches/CoachCard'
import { ActionBar } from '../../components/ActionBar'
import { Toolbar } from '../../components/Toolbar'
import useSearchable from '../../hooks/searchableHook'
import useSortable from '../../hooks/sortableHook'
import SortButton, { SortOrder } from '../../components/SortButton'
import SidePanel from '../../components/side-panel'
import { CAIcon } from '../../components/Chessadmin/Icon/CAIcon'

const Coaches: React.FC = () => {
  const coaches = useSelector((state: AppState) => state.coach.data)
  const user = useSelector((state: AppState) => state.user)
  const [addCoachSheetVisible, setAddCoachSheetVisible] = useState(false)

  const searchableCoaches = useSearchable(coaches, c => [c.formattedName])

  const [sort, setSort] = useState({ by: 'fullName', order: 'asc' as SortOrder })

  const sortableCoaches = useSortable(searchableCoaches.data, [sort.by], [sort.order])
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const openDrawer = () => setVisibleDrawer(true)
  const closeDrawer = () => setVisibleDrawer(false)

  return (
    <Content className="pt-8 px-2">
      <SidePanel visible={visibleDrawer} onClose={closeDrawer} />
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          {/* <CAIcon type="menu" height="10px" onClick={openDrawer} /> */}
          <span className="pt-4 text-lg text-primary-blue">Coaches</span>
        </div>
        <div className="flex-1 pr-4">
          {user?.role === 'ADMIN' && (
            <span className="float-right">
              <Button onClick={() => setAddCoachSheetVisible(true)}>
                Add Coach
                <PlusCircleOutlined />
              </Button>
            </span>
          )}
        </div>
      </div>

      <Toolbar
        actions={[
          <SortButton
            key="coaches-sort-by"
            options={{ fullName: 'Name' }}
            onChange={(by, order) => setSort({ by, order })}
          />
        ]}
      >
        <Input.Search placeholder="Search coaches" className="w-full" {...searchableCoaches.input} />
      </Toolbar>

      <ScrollView className="p-4">
        {sortableCoaches.map(coach => (
          <Link key={`coach-${coach.uuid}`} to={`/coaches/${coach.uuid}`}>
            <CoachCard coach={coach} />
          </Link>
        ))}
      </ScrollView>
      <AddCoachSheet visible={addCoachSheetVisible} onCancel={() => setAddCoachSheetVisible(false)} />
    </Content>
  )
}

export default Coaches
