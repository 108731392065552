import { createSlice } from '@reduxjs/toolkit'
import Academy from '../types/Academy'

const academySlice = createSlice({
  name: 'academySlice',
  initialState: {
    loading: false,
    selectedAcademyIndex: 0,
    data: [] as Academy[]
  },
  reducers: {
    set: (state, action) => {
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    },
    loading: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    },
    update: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export default academySlice
