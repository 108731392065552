import * as React from 'react'

function SvgBundlePack(props) {
  return (
    <svg id="bundle-pack_svg__Layer_1" x={0} y={0} viewBox="0 0 228 218.7" xmlSpace="preserve" {...props}>
      <style />
      <path d="M205.7 51.1H71.2c-12.3 0-22.3-10-22.3-22.3v-6.5C48.9 10 58.9 0 71.2 0h134.5C218 0 228 10 228 22.3v6.5c0 12.3-10 22.3-22.3 22.3zM71.2 11.6c-5.9 0-10.7 4.8-10.7 10.7v6.5c0 5.9 4.8 10.7 10.7 10.7h134.5c5.9 0 10.7-4.8 10.7-10.7v-6.5c0-5.9-4.8-10.7-10.7-10.7H71.2zM156.8 107H22.3C10 107 0 97 0 84.7v-6.5c0-12.3 10-22.3 22.3-22.3h134.5c12.3 0 22.3 10 22.3 22.3v6.5c0 12.3-10 22.3-22.3 22.3zM22.3 67.5c-5.9 0-10.7 4.8-10.7 10.7v6.5c0 5.9 4.8 10.7 10.7 10.7h134.5c5.9 0 10.7-4.8 10.7-10.7v-6.5c0-5.9-4.8-10.7-10.7-10.7H22.3zM194.8 161.6H60.3c-12.3 0-22.3-10-22.3-22.3v-6.5c0-12.3 10-22.3 22.3-22.3h134.5c12.3 0 22.3 10 22.3 22.3v6.5c0 12.3-10 22.3-22.3 22.3zM60.3 122.1c-5.9 0-10.7 4.8-10.7 10.7v6.5c0 5.9 4.8 10.7 10.7 10.7h134.5c5.9 0 10.7-4.8 10.7-10.7v-6.5c0-5.9-4.8-10.7-10.7-10.7H60.3zM194.8 218.7H60.3c-12.3 0-22.3-10-22.3-22.3v-6.5c0-12.3 10-22.3 22.3-22.3h134.5c12.3 0 22.3 10 22.3 22.3v6.5c0 12.2-10 22.3-22.3 22.3zM60.3 179.1c-5.9 0-10.7 4.8-10.7 10.7v6.5c0 5.9 4.8 10.7 10.7 10.7h134.5c5.9 0 10.7-4.8 10.7-10.7v-6.5c0-5.9-4.8-10.7-10.7-10.7H60.3z" />
    </svg>
  )
}

export default SvgBundlePack
