import * as React from 'react'

function SvgUser(props) {
  return (
    <svg id="user_svg__Layer_1" x={0} y={0} viewBox="0 0 249 231" xmlSpace="preserve" {...props}>
      <style />
      <path d="M124.5 159C80.7 159 45 123.3 45 79.5S80.7 0 124.5 0 204 35.7 204 79.5 168.3 159 124.5 159zm0-144C88.9 15 60 43.9 60 79.5S88.9 144 124.5 144 189 115.1 189 79.5 160.1 15 124.5 15z" />
      <path d="M241.5 231H7.5c-4.1 0-7.5-3.4-7.5-7.5 0-.6.1-1.2.2-1.8-.1-2.4-.2-4.8-.2-7.2 0-32.3 12.3-62.9 34.7-86.2 2.9-3 7.6-3.1 10.6-.2s3.1 7.6.2 10.6C25.8 159.2 15 186.1 15 214.5v1.5h219v-1.5c0-28.4-10.8-55.3-30.5-75.8-2.9-3-2.8-7.7.2-10.6 3-2.9 7.7-2.8 10.6.2 22.4 23.3 34.7 53.9 34.7 86.2 0 2.4-.1 4.8-.2 7.2.1.6.2 1.2.2 1.8 0 4.1-3.4 7.5-7.5 7.5z" />
    </svg>
  )
}

export default SvgUser
