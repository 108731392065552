import React, { useCallback, useState } from 'react'
import { UserOutlined, DeleteOutlined } from '@ant-design/icons'
import { Checkbox, Divider } from 'antd'
import Student from '../../types/Student'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'

interface Props {
  student: Student
  checkVisible: boolean
  checked: boolean
  onChange: (checked: boolean) => void
  onClick: (student: Student) => void
  showDelete?: boolean
  onDelete?: (student: Student) => void
}

const StudentCard: React.FC<Props> = props => {
  const { fullName, username } = props.student

  const [checked, setChecked] = useState(props.checked)

  const handleOnCheckChange = (event: CheckboxChangeEvent) => {
    setChecked(event.target.checked)
    props.onChange(checked)
  }

  const handleOnClick = useCallback(() => {
    if (props.checkVisible) {
      setChecked(!checked)
    } else {
      props.onClick(props.student)
    }
  }, [checked, props])

  const handleOnDelete = useCallback(
    event => {
      event.stopPropagation()
      props.onDelete && props.onDelete(props.student)
    },
    [props]
  )

  return (
    <div className="flex bg-gradient-primary text-white mb-4 p-4 rounded-lg" onClick={handleOnClick}>
      {props.checkVisible && <Checkbox className="my-auto mr-3" checked={checked} onChange={handleOnCheckChange} />}
      <UserOutlined className="text-xl" />
      <div className="ml-2">
        <div>
          {fullName} / <span className="text-sm">{username}</span>
        </div>
        {/* <div className="text-xs">Added on Jan 1, 2020</div> */}
      </div>
      {props.showDelete && !props.checkVisible && (
        <>
          <Divider type="vertical" className="ml-auto h-6 self-center" />
          <DeleteOutlined className="text-lg self-center" onClick={handleOnDelete} />
        </>
      )}
    </div>
  )
}

StudentCard.defaultProps = {
  showDelete: false
}

export default StudentCard
