import axios from 'axios'
import userSlice from './userSlice'
import studentSlice from './studentSlice'
import classSlice from './classLogSlice'
import groupSlice from './groupSlice'
import coachSlice from './coachSlice'
import academySlice from './academySlice'
import addCoachSlice from './addCoachSlice'
import ActionState from '../types/ActionState'
import allocateStudentSlice from './allocateStudentSlice'
import useAccessToken from '../hooks/accessTokenHook'
import packSlice from './packSlice'
import updatePackStudentSlice from './updatePackStudentSlice'
import ClassStudent from '../types/ClassStudent'
import classLogSlice from './classLogSlice'
import invoiceSlice from './invoiceSlice'
import updatePackSlice from './updatePackSlice'
import Group from '../types/Group'
import { decode } from 'jwt-simple'
import axiosClient from '../config/axios'
import UserData from '../types/UserData'

const { setAccessToken } = useAccessToken()

export const login = (username: string, password: string) => async (dispatch: Function) => {
  try {
    const response = await axios.post('https://api-core.chesslang.com/api/v2/identity/oauth/token', {
      username,
      password,
      // eslint-disable-next-line @typescript-eslint/camelcase
      grant_type: 'password',
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: 'default',
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_secret: 'xyzfgh'
    })

    const accessToken = response.data.access_token
    setAccessToken(accessToken)

    const jwt = decode(accessToken, '', true)

    dispatch(userSlice.actions.login(jwt.user))

    return Promise.resolve()
  } catch (ex) {
    return Promise.reject('Login failed')
  }
}

export const getAcademyUser = (userId: string) => async (dispatch: Function) => {
  try {
    const response = await axiosClient.get(`/academy-users/${userId}`)

    dispatch(userSlice.actions.setRole(response.data.role))

    return response.data
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const createUser = (payload: any) => {
  return async (dispatch: Function) => {
    try {
      const response = await axios.post('https://api-core.chesslang.com/api/v2/identity/account/signup', payload)
      if (response.status === 200) {
        dispatch(login(payload.username, payload.password))
      }
    } catch (ex) {}
  }
}

export const createStudent = (userData: UserData) => async (dispatch: Function) => {
  try {
    await axiosClient.post('/students', userData)
    dispatch(getStudents())
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const getAcademies = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(academySlice.actions.loading(true))

      const response = await axiosClient.get('/academies')
      dispatch(academySlice.actions.set(response.data.records))
    } catch (ex) {
    } finally {
      dispatch(academySlice.actions.loading(false))
    }
  }
}

// TODO: Move academyId into axios client to scope all requests for an academy

export const getCoaches = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(coachSlice.actions.loading(true))

      const response = await axiosClient.get('/coaches')

      dispatch(coachSlice.actions.set(response.data.records))
    } catch (ex) {
    } finally {
      dispatch(coachSlice.actions.loading(false))
    }
  }
}

export const deleteCoach = (coachId: string) => async (dispatch: Function) => {
  try {
    const response = await axiosClient.delete(`/academy-users/${coachId}`)
    dispatch(getCoaches())
    return response.data
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const getStudents = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(studentSlice.actions.loading(true))

      const response = await axiosClient.get('/students')

      dispatch(studentSlice.actions.set(response.data.records))
    } catch (ex) {
    } finally {
      dispatch(studentSlice.actions.loading(false))
    }
  }
}

export const getUserMoreInfo = () => {
  return async (dispatch: Function) => {
    try {
      const response = await axiosClient.get('/coaches/me')
      if (response.data) {
        dispatch(userSlice.actions.setMoreInfo(response.data))
      }
    } catch (ex) {
      return Promise.reject(ex)
    }
  }
}

export const putUserMoreInfo = (firstname: string, lastname: string, email: string, phone: string, gender: string) => {
  return async (dispatch: Function) => {
    try {
      const response = await axiosClient.put('/coaches/me', {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone ? phone : '',
        gender
      })
      if (response.data) {
        dispatch(userSlice.actions.setMoreInfo(response.data))
      }
    } catch (ex) {
      return Promise.reject(ex)
    }
  }
}

export const getGroups = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(groupSlice.actions.loading(true))

      const response = await axiosClient.get('/groups')

      dispatch(groupSlice.actions.set(response.data.records))
    } catch (ex) {
    } finally {
      dispatch(groupSlice.actions.loading(false))
    }
  }
}

export const createGroup = (values: Group) => async (dispatch: Function) => {
  try {
    const response = await axiosClient.post('/groups', values)
    dispatch(getGroups())
    return Promise.resolve(response)
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const deleteGroup = (id: string) => async (dispatch: Function) => {
  try {
    const response = await axiosClient.delete(`/groups/${id}`)
    dispatch(getGroups())
    return Promise.resolve(response)
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const addStudentsToGroup = (data: { studentIds: string[]; groupId: string }) => async (dispatch: Function) => {
  try {
    await axiosClient.post('/group-students', data)
    dispatch(getGroups())
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const deleteGroupStudent = (data: { groupId: string; studentId: string }) => async (dispatch: Function) => {
  try {
    await axiosClient.delete(`/group-students`, {
      data
    })
    dispatch(getGroups())
    return Promise.resolve()
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const getClasses = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(classSlice.actions.loading(true))

      const response = await axiosClient.get('/classes')

      dispatch(classSlice.actions.set(response.data.records))
    } catch (ex) {
    } finally {
      dispatch(classSlice.actions.loading(false))
    }
  }
}

export const createClass = (values: object) => async (dispatch: Function) => {
  try {
    const response = await axiosClient.post('/classes', values)
    dispatch(getClasses())
    dispatch(getInvoices())
    return response.data
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const deleteClass = (classId: string) => async (dispatch: Function) => {
  try {
    const response = await axiosClient.delete(`/classes/${classId}`)
    dispatch(getClasses())
    return response.data
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const updateAttendance = (classStudent: Pick<ClassStudent, 'id' | 'attended'>) => {
  return async (dispatch: Function) => {
    try {
      const response = await axiosClient.post('/classes/attendance', classStudent)
      dispatch(classLogSlice.actions.updateAttendance(response.data))
    } catch (ex) {
      // dispatch(createClassSlice.actions.actionState(ActionState.ERROR))
    }
  }
}

export const addCoach = (academyId: string, username: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(addCoachSlice.actions.actionState(ActionState.LOADING))
      const response = await axiosClient.post('/coaches', {
        academyId,
        username
      })

      dispatch(addCoachSlice.actions.actionState(response.data.success ? ActionState.SUCCESS : ActionState.ERROR))
      if (response.data.success) {
        dispatch(getCoaches())
      }
    } catch (ex) {
      dispatch(addCoachSlice.actions.actionState(ActionState.ERROR))
    }
  }
}

export const deleteCoachStudent = (data: { studentId: string; coachId: string }) => async (dispatch: Function) => {
  try {
    await axiosClient.delete(`/coach-students`, {
      data
    })
    dispatch(getCoaches())
    return Promise.resolve()
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const allocateStudents = (coachId: string, studentIds: string[]) => {
  return async (dispatch: Function) => {
    try {
      dispatch(allocateStudentSlice.actions.actionState(ActionState.LOADING))
      await axiosClient.post(`/coaches/${coachId}`, {
        studentIds
      })
      dispatch(allocateStudentSlice.actions.actionState(ActionState.SUCCESS))
      dispatch(getCoaches())
    } catch (ex) {
      dispatch(allocateStudentSlice.actions.actionState(ActionState.ERROR))
    }
  }
}

export const getPacks = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(packSlice.actions.status(ActionState.LOADING))

      const response = await axiosClient.get('/packs')

      dispatch(packSlice.actions.set(response.data.records))
    } catch (ex) {
      dispatch(packSlice.actions.status(ActionState.ERROR))
    } finally {
      dispatch(packSlice.actions.status(ActionState.SUCCESS))
    }
  }
}

export const createPack = (academyId: string, values: object) => async (dispatch: Function) => {
  try {
    await axiosClient.post('/packs', { academyId, ...values })
    dispatch(getPacks())
    return Promise.resolve()
  } catch (ex) {
    return Promise.reject(ex)
  }
}

export const updatePackStudents = (packId: string, studentIds: string[]) => {
  return async (dispatch: Function) => {
    try {
      dispatch(updatePackStudentSlice.actions.actionState(ActionState.LOADING))
      await axiosClient.put(`/packs/${packId}`, { studentIds })
      dispatch(updatePackStudentSlice.actions.actionState(ActionState.SUCCESS))
      dispatch(getPacks())
    } catch (ex) {
      dispatch(updatePackStudentSlice.actions.actionState(ActionState.ERROR))
    }
  }
}

export const updatePackPrice = (packId: string, newPrice: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch(updatePackSlice.actions.status(ActionState.LOADING))
      await axiosClient.post(`/packs/update-price`, { packId, newPrice })
      dispatch(updatePackSlice.actions.status(ActionState.SUCCESS))
      dispatch(getPacks())
    } catch (ex) {
      dispatch(updatePackSlice.actions.status(ActionState.ERROR))
    }
  }
}

export const getInvoices = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(invoiceSlice.actions.status(ActionState.LOADING))

      const response = await axiosClient.get('/invoices')

      dispatch(invoiceSlice.actions.set(response.data.records))
    } catch (ex) {
      dispatch(invoiceSlice.actions.status(ActionState.ERROR))
    } finally {
      dispatch(invoiceSlice.actions.status(ActionState.SUCCESS))
    }
  }
}
