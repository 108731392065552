import React from 'react'
import { PicLeftOutlined, UserOutlined } from '@ant-design/icons'
import Class from '../../types/Class'
import moment from 'moment'
import _ from 'lodash'

const StudentClasses: React.FC<{ classes: Class[] }> = ({ classes }) => {
  return (
    <>
      {_.size(classes) ? (
        classes.map(c => <Card key={`class-${c.id}`} classObj={c} />)
      ) : (
        <p className="mt-4 text-center">No Classes</p>
      )}
      {}
    </>
  )
}

const Card: React.FC<{ classObj: Class }> = ({ classObj }) => {
  const startedAt = moment(classObj.happenedAt).format('ddd, DD MMM YYYY hh:mm a')
  const endedAt = moment(classObj.happenedAt)
    .add(classObj.duration, 'M')
    .format('hh:mm a')
  return (
    <div className="m-4 p-4 bg-gradient-primary rounded-lg text-white">
      <div className="flex mb-3">
        <div className="mr-3">
          <PicLeftOutlined />
        </div>
        <div>
          <div>{classObj.pack?.name || '<No Pack>'}</div>
          <div className="text-xs">
            {startedAt} - {endedAt}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="mr-3">
          <UserOutlined />
        </div>
        <div>
          {classObj.coach.fullName} / {classObj.coach.username}
        </div>
      </div>
    </div>
  )
}

export default StudentClasses
