import { createSlice } from '@reduxjs/toolkit'
import Student from '../types/Student'
import { userWithInfo } from '../utils/FormattingUtils'

const studentSlice = createSlice({
  name: 'studentSlice',
  initialState: { data: [] as Student[], loading: false },
  reducers: {
    set: (_, action) => {
      return {
        loading: false,
        data: action.payload.map((s: Student) => userWithInfo(s))
      }
    },
    loading: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    }
  }
})

export default studentSlice
