import { useState, useCallback } from 'react'

const useUserInput = <T>(
  defaultValue: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueSetter: (v: any, sv: any) => any = (v, sv) => sv(v.target.value)
) => {
  const [value, setValue] = useState(defaultValue)

  return {
    value,
    onChange: useCallback(e => valueSetter(e, setValue), [valueSetter])
  }
}

export default useUserInput
