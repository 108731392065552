import { createSlice } from '@reduxjs/toolkit'
import Group from '../types/Group'

const groupSlice = createSlice({
  name: 'groupSlice',
  initialState: {
    data: [] as Group[],
    loading: false
  },
  reducers: {
    set: (state, action) => {
      return {
        ...state,
        data: action.payload.map((g: Group) => {
          return {
            ...g,
            studentCount: g.students?.length || 0
          }
        })
      }
    },
    loading: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    }
  }
})

export default groupSlice
