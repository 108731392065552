import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../store/store'
import { ActionBar } from '../../components/ActionBar'
import Content, { ScrollView } from '../../components/Content'
import StudentList from '../../components/StudentList'
import Student from '../../types/Student'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, message, InputNumber } from 'antd'
import AllocateStudents from '../../components/coaches/modals/AllocateStudents'
import ActionState from '../../types/ActionState'
import updatePackStudentSlice from '../../store/updatePackStudentSlice'
import { updatePackStudents, updatePackPrice } from '../../store/thunks'
import BottomSheet from '../../components/BottomSheet'
import useValueInput from '../../hooks/valueInputHook'
import useDataStatus from '../../hooks/dataStatusHook'
import updatePackSlice from '../../store/updatePackSlice'
import { CAIcon } from '../../components/Chessadmin/Icon/CAIcon'

interface Params {
  packId: string
}

const PackStudents: React.FC = () => {
  const { packId } = useParams<Params>()
  const dispatch = useDispatch()
  const history = useHistory()

  const pack = useSelector((state: AppState) => state.pack.data.find(p => p.id === packId))

  const [allocateStudentModal, setAllocateStudentModal] = useState(false)

  const students: Student[] = pack?.students || []

  const actionState = useSelector((state: AppState) => state.updatePackStudent.actionState)

  const editSheetVisible = useValueInput(false)
  const newPrice = useValueInput(pack?.price || 1)

  const updatePackStatus = useDataStatus(
    state => state.updatePack.status,
    successful => {
      if (successful) {
        message.success('Pack updated successfully!')
        history.goBack()
        editSheetVisible.onChange(false)
      } else {
        message.error('Oops! Something went wrong')
      }
      dispatch(updatePackSlice.actions.status(ActionState.INITIAL))
    }
  )

  const handlePackUpdate = useCallback(() => {
    dispatch(updatePackPrice(packId, newPrice.value))
  }, [dispatch, newPrice.value, packId])

  useEffect(() => {
    if (actionState !== ActionState.LOADING) {
      if (actionState === ActionState.SUCCESS) {
        message.success('Students allocate successfully')
        setAllocateStudentModal(false)
      } else if (actionState === ActionState.ERROR) {
        message.error('Oops! Something went wrong')
      }
      dispatch(updatePackStudentSlice.actions.actionState(ActionState.INITIAL))
    }
  }, [actionState, dispatch])

  const handleOnOk = (studentIds: string[]) => {
    dispatch(updatePackStudents(pack?.id as string, studentIds))
  }

  return (
    <Content className="mt-8 mx-2">
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          <CAIcon type="arrow-left" height="10px" onClick={() => history.goBack()} />
          <span className="pt-4 pl-2 text-lg text-primary-blue">{pack?.name}</span>
        </div>
        <div className="flex-1 pr-4">
          <Button key="packs-add-students" className="mr-1 float-right" onClick={() => setAllocateStudentModal(true)}>
            Students
            <PlusCircleOutlined />
          </Button>
          <Button key="packs-add-students" className="mr-1 float-right" onClick={() => editSheetVisible.onChange(true)}>
            Edit
            <EditOutlined />
          </Button>
        </div>
      </div>
      <ScrollView>
        <StudentList data={students} actions={[]} />
      </ScrollView>
      <AllocateStudents
        visible={allocateStudentModal}
        onCancel={() => setAllocateStudentModal(false)}
        excludeStudentIds={students.map(s => s.uuid)}
        onOk={handleOnOk}
        loading={actionState === ActionState.LOADING}
      />
      <BottomSheet {...editSheetVisible}>
        <div className="p-4">
          <div className="mb-2">Price</div>
          <InputNumber className="w-full mb-4" min={1} placeholder="New price" {...newPrice} />
          <Button type="primary" block onClick={handlePackUpdate} loading={updatePackStatus === ActionState.LOADING}>
            Save
          </Button>
        </div>
      </BottomSheet>
    </Content>
  )
}

export default PackStudents
