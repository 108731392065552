import { createSlice } from '@reduxjs/toolkit'
import Pack from '../types/Pack'
import ActionState from '../types/ActionState'

const packSlice = createSlice({
  name: 'packSlice',
  initialState: {
    data: [] as Pack[],
    status: ActionState.INITIAL
  },
  reducers: {
    set: (state, action) => {
      return {
        ...state,
        data: action.payload
      }
    },
    status: (state, action) => {
      return {
        ...state,
        status: action.payload
      }
    }
  }
})

export default packSlice
