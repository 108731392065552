import { combineReducers } from 'redux'
import academySlice from './academySlice'
import userSlice from './userSlice'
import studentSlice from './studentSlice'
import coachSlice from './coachSlice'
import classLogSlice from './classLogSlice'
import groupSlice from './groupSlice'
import addCoachSlice from './addCoachSlice'
import allocateStudentSlice from './allocateStudentSlice'
import packSlice from './packSlice'
import updatePackStudentSlice from './updatePackStudentSlice'
import invoiceSlice from './invoiceSlice'
import updatePackSlice from './updatePackSlice'
import { useDispatch } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

const rootReducer = combineReducers({
  academy: academySlice.reducer,
  user: userSlice.reducer,
  students: studentSlice.reducer,
  groups: groupSlice.reducer,
  coach: coachSlice.reducer,
  classLog: classLogSlice.reducer,
  addCoach: addCoachSlice.reducer,
  allocateStudent: allocateStudentSlice.reducer,
  pack: packSlice.reducer,
  updatePackStudent: updatePackStudentSlice.reducer,
  invoice: invoiceSlice.reducer,
  updatePack: updatePackSlice.reducer
})

export type AppState = ReturnType<typeof rootReducer>

const store = configureStore({ reducer: rootReducer })

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>()

export default store
