import { createSlice } from '@reduxjs/toolkit'
import { formatUserName, fullUserName } from '../utils/FormattingUtils'
import Coach from '../types/Coach'

const coachSlice = createSlice({
  name: 'coachSlice',
  initialState: {
    loading: false,
    data: [] as Coach[]
  },
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    set: (_, action) => {
      return {
        loading: false,
        data: action.payload.map((s: Coach) => {
          return { ...s, formattedName: formatUserName(s), fullName: fullUserName(s) }
        })
      }
    },
    loading: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    }
  }
})

export default coachSlice
