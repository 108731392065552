import React, { useState, useEffect } from 'react'
import { Typography, Input, Radio, Button, Form } from 'antd'
import { CAIcon } from '../components/Chessadmin/Icon/CAIcon'
import { useHistory } from 'react-router-dom'
import TextLoop from 'react-text-loop'
import UserFemale from '../../src/assets/user'
import UserMale from '../../src/assets/usermale'
import { getUserMoreInfo, putUserMoreInfo } from '../store/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../store/store'
import CAButton from '../components/Chessadmin/Button'

type User = {
  email: string
  phoneNumber: string
  firstname: string
  lastname: string
  gender: string
  iss: string
  exp: string
  uuid: string
  username: string
}

type UserInfo = {
  gender: string
  phoneNumber: string
  email: string
}

const Profile: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(false)
  const user = useSelector((state: AppState) => state.user.user)
  const userInfo = useSelector((state: AppState) => state.user.moreInfo)
  useEffect(() => {
    dispatch(getUserMoreInfo())
  }, [])

  return (
    <div className="mt-8 mx-2">
      {/* <SidePanel visible={visibleDrawer} onClose={closeDrawer} /> */}
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          <CAIcon type="arrow-left" height="15px" onClick={() => history.push('/')} />
          <span className="pl-4 pt-4 text-lg text-primary-blue">Profile</span>
        </div>
        <div className="flex-1 pr-4">
          <span className="float-right">
            <Button onClick={() => setEditMode(!editMode)}>{!editMode ? 'Edit' : 'Done'}</Button>
          </span>
        </div>
      </div>
      {editMode ? (
        <ProfileForm user={user} userInfo={userInfo} />
      ) : (
        <div className="grid grid-rows-1 grid-flow-row mx-6">
          <div className="flex mt-8">
            <div className="flex-1">
              <div className="mt-12 pr-8">
                <p className="text-primary-blue text-xl">Hey {user.firstname + ' ' + user.lastname} </p>
              </div>
            </div>
            <div className="flex-1">{userInfo.gender === 'M' ? <UserMale /> : <UserFemale />}</div>
          </div>
          <div className="mt-8">
            <p className="text-primary-blue">Email</p>
            <p className="text-primary-blue text-xl -mt-4">{userInfo.email}</p>
          </div>
          <div className="mt-4">
            <p className="text-primary-blue">Phone</p>
            <p className="text-primary-blue text-xl -mt-4">{userInfo.phoneNumber ? userInfo.phoneNumber : '-'}</p>
          </div>
          <div className="mt-4">
            <p className="text-primary-blue">Gender</p>
            <p className="text-primary-blue text-xl -mt-4">
              {userInfo.gender === 'M' ? 'Male' : userInfo.gender === 'F' ? 'Female' : 'Others'}
            </p>
          </div>
          {/* <div className="mt-4">
            <p className="text-primary-blue">Address</p>
            <p className="text-primary-blue text-xl -mt-4">Dundermifflin, Scranton</p>
          </div> */}
          {/* <div className="mt-4">
            <p className="text-primary-blue">Rating</p>
            <p className="text-primary-blue text-xl -mt-4">4/5</p>
          </div> */}
          <div className="flex-none mt-4 mb-12">
            <p className="text-primary-blue text-center">Made with ♥ remotely</p>
            <div className="-mt-4 text-primary-blue text-center">
              from{' '}
              <span>
                <TextLoop interval={2000}>
                  <span>Chennai, IN</span>
                  <span>Hyderbad, IN</span>
                  <span>Bangalore, IN</span>
                  <span>Singapore</span>
                  <span>Berlin, Germany</span>
                  <span>Nigeria</span>
                </TextLoop>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
interface CustomInputProps {
  user: any
  userInfo: any
}

const ProfileForm: React.FC<CustomInputProps> = props => {
  const { user, userInfo } = props
  const dispatch = useDispatch()
  const handleOnFinish = ({ firstname, lastname, email, phone, gender }: any) => {
    dispatch(putUserMoreInfo(firstname, lastname, email, phone, gender))
  }

  const handleOnFinishFailed = () => {
    console.log('failed')
  }
  const [formEdit] = Form.useForm()
  return (
    <Form
      form={formEdit}
      className="mx-4 mt-4"
      onFinish={handleOnFinish}
      onFinishFailed={handleOnFinishFailed}
      initialValues={{
        firstname: user.firstname,
        lastname: user.lastname,
        email: userInfo.email,
        phone: userInfo.phoneNumber,
        gender: userInfo.gender
      }}
    >
      <Form.Item name="firstname" label="First name" rules={[{ required: true, message: 'Please enter first name!' }]}>
        <Input className="mb-4" placeholder="First name" />
      </Form.Item>

      <Form.Item name="lastname" label="Last name" rules={[{ required: true, message: 'Please enter last name!' }]}>
        <Input className="mb-4" placeholder="Last name" />
      </Form.Item>

      <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter email!' }]}>
        <Input className="mb-4" placeholder="Email" />
      </Form.Item>

      <Form.Item name="phone" label="Phone" rules={[{ message: 'Please phone number!' }]}>
        <Input className="mb-4" placeholder="Phone" />
      </Form.Item>

      <Form.Item name="gender" label="Gender" rules={[{ required: true, message: 'Please select gender!' }]}>
        <Radio.Group className="mb-4">
          <Radio value="M">Male</Radio>
          <Radio value="F">Female</Radio>
        </Radio.Group>
      </Form.Item>

      {/* <Form.Item label="Address Line 1" rules={[{ required: true, message: 'Please enter address!' }]}>
        <Input className="mb-4" placeholder="Address Line 1" />
      </Form.Item>

      <Form.Item label="Address Line 2" rules={[{ required: true, message: 'Please address enter!' }]}>
        <Input className="mb-4" placeholder="Address Line 2" />
      </Form.Item>

      <Form.Item label="Rating" rules={[{ required: true, message: 'Please rating number!' }]}>
        <Input className="mb-4" placeholder="Rating" />
      </Form.Item> */}

      <Form.Item>
        <CAButton text="Save" type="primary" onClick={() => formEdit.submit} />
      </Form.Item>
    </Form>
  )
}

export default Profile
