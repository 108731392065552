import * as React from 'react'

function SvgHome(props) {
  return (
    <svg id="home_svg__Layer_1" x={0} y={0} viewBox="0 0 231 252.7" xmlSpace="preserve" {...props}>
      <style />
      <path d="M228.8 110.2l-108-108c-2.9-2.9-7.7-2.9-10.6 0l-108 108c-2.9 2.9-2.9 7.7 0 10.6 2.9 2.9 7.7 2.9 10.6 0L115.5 18.1l102.7 102.7c1.5 1.5 3.4 2.2 5.3 2.2s3.8-.7 5.3-2.2c2.9-2.9 2.9-7.7 0-10.6z" />
      <path d="M192 112.5H39c-4.1 0-7.5 3.4-7.5 7.5v125.2c0 4.1 3.4 7.5 7.5 7.5h153c4.1 0 7.5-3.4 7.5-7.5V120c0-4.1-3.4-7.5-7.5-7.5zM92.8 230.4c0-12.5 10.2-22.7 22.7-22.7s22.7 10.2 22.7 22.7c0 2.5-.4 4.9-1.2 7.3H94c-.8-2.3-1.2-4.8-1.2-7.3zm91.7 7.3h-32c.5-2.4.7-4.8.7-7.3 0-20.8-16.9-37.7-37.7-37.7s-37.7 16.9-37.7 37.7c0 2.4.2 4.9.7 7.3h-32V127.5h138v110.2z" />
    </svg>
  )
}

export default SvgHome
