/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import ArrowRight from '../../../assets/icons/arrow-right'
import ArrowLeft from '../../../assets/icons/arrow-left'
import Coaches from '../../../assets/icons/coaches'
import Home from '../../../assets/icons/home'
import Students from '../../../assets/icons/students'
import Class from '../../../assets/icons/class'
import User from '../../../assets/icons/user'
import More from '../../../assets/icons/more'
import Logout from '../../../assets/icons/logout'
import BundlePeriodic from '../../../assets/icons/bundle-periodic'
import BundlePack from '../../../assets/icons/bundle-pack'
import Menu from '../../../assets/icons/menu'
import Billing from '../../../assets/icons/billing'
import './index.scss'

type IconProps = {
  height?: string
  onClick?: () => void
  color?: string
  button?: boolean
}

const components = {
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  coaches: Coaches,
  home: Home,
  students: Students,
  class: Class,
  user: User,
  more: More,
  logout: Logout,
  'bundle-periodic': BundlePeriodic,
  'bundle-pack': BundlePack,
  menu: Menu,
  billing: Billing
}

const createIconComponent = (icon: keyof typeof components) => {
  return (props: IconProps) => {
    // eslint-disable-next-line prefer-const
    let { height, onClick, color } = props
    if (!height) {
      height = '25px'
    }
    const SpecificIcon = components[icon]
    return (
      <SpecificIcon
        onClick={onClick}
        style={{
          fill: color,
          height: height,
          marginBottom: props.button ? '-3px' : '0px'
        }}
      />
    )
  }
}

export const ArrowLeftIcon: React.FC<IconProps> = createIconComponent('arrow-left')
export const ArrowRightIcon: React.FC<IconProps> = createIconComponent('arrow-right')
export const CoachesIcon: React.FC<IconProps> = createIconComponent('coaches')
export const HomeIcon: React.FC<IconProps> = createIconComponent('home')
export const StudentsIcon: React.FC<IconProps> = createIconComponent('students')
export const ClassIcon: React.FC<IconProps> = createIconComponent('class')
export const UserIcon: React.FC<IconProps> = createIconComponent('user')
export const MoreIcon: React.FC<IconProps> = createIconComponent('more')
export const LogoutIcon: React.FC<IconProps> = createIconComponent('logout')
export const BundlePeriodicIcon: React.FC<IconProps> = createIconComponent('bundle-periodic')
export const BundlePackIcon: React.FC<IconProps> = createIconComponent('bundle-pack')
export const MenuIcon: React.FC<IconProps> = createIconComponent('menu')
export const BillingIcon: React.FC<IconProps> = createIconComponent('billing')
