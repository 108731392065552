import React, { useEffect } from 'react'
import { Input, Button, message } from 'antd'
import { AppState } from '../../store/store'
import { useSelector, useDispatch } from 'react-redux'
import ActionState from '../../types/ActionState'
import { addCoach } from '../../store/thunks'
import addCoachSlice from '../../store/addCoachSlice'
import useAcademy from '../../hooks/academyHook'
import Academy from '../../types/Academy'
import BottomSheet from '../BottomSheet'
import useUserInput from '../../hooks/userInputHook'

interface Props {
  visible: boolean
  onCancel: () => void
}

const AddCoachSheet: React.FC<Props> = props => {
  const username = useUserInput('')
  const dispatch = useDispatch()
  const academy = useAcademy() as Academy
  const actionState = useSelector((state: AppState) => state.addCoach.actionState)
  const loading = actionState === ActionState.LOADING

  useEffect(() => {
    if (!loading) {
      if (actionState === ActionState.SUCCESS) {
        message.success('Coach add successfully')
        props.onCancel()
      } else if (actionState === ActionState.ERROR) {
        message.error("Username or Email doesn't exists")
      }
      dispatch(addCoachSlice.actions.actionState(ActionState.INITIAL))
    }
  }, [actionState, loading, dispatch, props])

  const handleOk = () => {
    if (!loading) {
      dispatch(addCoach(academy.id, username.value))
    }
  }

  return (
    <BottomSheet value={props.visible} onChange={props.onCancel}>
      <div className="p-4">
        <div className="mb-2 font-bold">Username / Email</div>
        <Input placeholder="Username / Email" type="text" className="mb-4" {...username}></Input>
        <div className="flex">
          <Button className="flex-1 mr-1" key="back" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button className="flex-1 ml-1" key="submit" type="primary" loading={loading} onClick={handleOk}>
            Add
          </Button>
        </div>
      </div>
    </BottomSheet>
  )
}

export default AddCoachSheet
