import React, { useState, useCallback } from 'react'
import { ActionBar } from '../../components/ActionBar'
import { Select, Input, Button, DatePicker, TimePicker, Row, Col, message, Form } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import Content, { ScrollView } from '../../components/Content'
import { AppState, useThunkDispatch } from '../../store/store'
import { useSelector } from 'react-redux'
import { createClass } from '../../store/thunks'
import moment from 'moment'
import _ from 'lodash'
import useAcademy from '../../hooks/academyHook'
import Academy from '../../types/Academy'

const { Option } = Select

interface FormValues {
  recurrence: 'scheduled' | 'single'
  type: string
  duration: number
  date: moment.Moment
  time: moment.Moment
  attendees: string[]
  packId: string | null
  description: string
}

const CreateClass: React.FC = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const classes = useSelector((state: AppState) => state.classLog.data)

  let initialValues: FormValues = {
    recurrence: 'single',
    type: 'OFFLINE',
    duration: 15,
    date: moment(),
    time: moment('00:00 AM', 'hh:mm A'),
    attendees: [],
    packId: null,
    description: ''
  }

  if (query.has('cloneFrom')) {
    const cloneFrom = classes.find(c => c.id === query.get('cloneFrom'))
    if (cloneFrom) {
      initialValues = {
        ...initialValues,
        type: cloneFrom.type,
        date: moment(cloneFrom.happenedAt),
        time: moment(cloneFrom.happenedAt, 'hh:mm A'),
        duration: cloneFrom.duration,
        attendees: cloneFrom.students?.map(s => `student_${s.studentId}`),
        packId: cloneFrom.packId,
        description: cloneFrom.description
      }
    }
  }

  return (
    <Content>
      <ActionBar title="Create Class" />
      <ScrollView className="p-4">
        <CreateClassForm initialValues={initialValues} />
      </ScrollView>
    </Content>
  )
}

const CreateClassForm: React.FC<{ initialValues: FormValues }> = ({ initialValues }) => {
  const history = useHistory()
  const thunkDispatch = useThunkDispatch()
  const groups = useSelector((state: AppState) => state.groups.data)
  const students = useSelector((state: AppState) => state.students.data)
  const academy = useAcademy() as Academy
  const packs = useSelector((state: AppState) => state.pack.data)

  const [inFlight, setInFlight] = useState(false)

  const getGroupStudentIds = useCallback(
    (groupIds: string[]): string[] => {
      const studentIds: string[] = []
      groupIds
        .map(id => groups.find(g => g.id === id))
        .filter(g => g !== undefined)
        .map(g => g?.students.map(s => s.uuid))
        .forEach(ids => {
          if (ids !== undefined) {
            studentIds.push(...ids)
          }
        })
      return studentIds
    },
    [groups]
  )

  const handleOnFinish = useCallback(
    async (values: any) => {
      const date = values.date.set({
        hour: values.time.get('hour'),
        minute: values.time.get('minute')
      })

      const datetime = date.format()

      const studentIds = _.uniq([...values.studentIds, ...getGroupStudentIds(values.groupIds)])

      const data = {
        academyId: academy.id,
        packId: values.packId,
        type: values.type,
        happenedAt: datetime,
        studentIds,
        groupIds: values.groupIds,
        duration: values.duration,
        description: values.description
      }

      try {
        setInFlight(true)
        const clazz = await thunkDispatch(createClass(data))
        message.success('Class created successfully!')
        history.replace(`/classes/${clazz.id}/attendance`)
      } catch {
        message.error('Error occured while creating class')
      } finally {
        setInFlight(false)
      }
    },
    [academy, getGroupStudentIds, history, thunkDispatch]
  )

  const filterOption = useCallback((searchText, option) => {
    const regex = new RegExp(searchText, 'i')
    return regex.test(option.children)
  }, [])

  return (
    <Form onFinish={handleOnFinish} initialValues={initialValues}>
      {/* <Form.Item
        label="Recurrence"
        name="recurrence"
        rules={[{ required: true, message: 'Please select class Recurrence!' }]}
      >
        <Select className="w-full mb-4" placeholder="Select">
          <Option value="scheduled">Scheduled</Option>
          <Option value="single">Single</Option>
        </Select>
      </Form.Item> */}

      <Form.Item label="Class Type" name="type" rules={[{ required: true, message: 'Please select class type!' }]}>
        <Select className="w-full mb-4" placeholder="Select">
          <Option value="ONLINE">Online</Option>
          <Option value="OFFLINE">Offline</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Class Duration"
        name="duration"
        rules={[{ required: true, message: 'Please select class type!' }]}
      >
        <Select className="w-full mb-4" placeholder="Select">
          <Option value={15}>15 Minutes</Option>
          <Option value={30}>30 Minutes</Option>
          <Option value={45}>45 Minutes</Option>
          <Option value={60}>1 Hour</Option>
          <Option value={90}>1.5 Hours</Option>
          <Option value={120}>2 Hours</Option>
          <Option value={150}>2.5 Hours</Option>
          <Option value={180}>3 Hours</Option>
        </Select>
      </Form.Item>

      <Row>
        <Col span={11}>
          <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Please select date!' }]}>
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item label="Time" name="time" rules={[{ required: true, message: 'Please select time!' }]}>
            <TimePicker format="hh:mm A" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Add Students" name="studentIds" rules={[{ required: true, message: 'Please add students!' }]}>
        <Select className="w-full mb-4" mode="multiple" filterOption={filterOption}>
          {students.map(s => (
            <Option key={`student-${s.uuid}`} value={s.uuid}>
              {s.formattedName}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Add Groups" name="groupIds">
        <Select className="w-full mb-4" mode="multiple" filterOption={filterOption}>
          {groups.map(g => (
            <Option key={`group-${g.id}`} value={g.id}>
              {g.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Package" name="packId" rules={[{ required: true, message: 'Please select Package!' }]}>
        <Select className="w-full mb-4" placeholder="Select" showSearch={true} filterOption={filterOption}>
          {packs.map(p => (
            <Option key={`pack-${p.id}`} value={p.id}>
              {p.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea rows={4} className="mb-4" />
      </Form.Item>

      <Form.Item>
        <Button className="mb-4" block type="primary" htmlType="submit" loading={inFlight}>
          Save & Record
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CreateClass
