import React, { useState } from 'react'
import Content, { ScrollView } from '../../components/Content'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/store'
import InvoiceCard from '../../components/billing/invoices/InvoiceCard'
import SidePanel from '../../components/side-panel'
import { CAIcon } from '../../components/Chessadmin/Icon/CAIcon'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'

const Invoices: React.FC = () => {
  const invoices = useSelector((state: AppState) => state.invoice.data)
  const user = useSelector((state: AppState) => state.user)
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const openDrawer = () => setVisibleDrawer(true)
  const closeDrawer = () => setVisibleDrawer(false)
  const outstandingAmount = () => {
    let total = 0
    invoices.map(item => (total += item.amount))

    return 'Rs ' + total
  }
  const outstandingNumber = () => {
    let total = 0
    total = invoices.length

    return total
  }
  return (
    <Content className="pt-8 px-2">
      <SidePanel visible={visibleDrawer} onClose={closeDrawer} />
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          {/* <CAIcon type="menu" height="10px" onClick={openDrawer} /> */}
          <span className="pt-4 text-lg text-primary-blue">Invoices</span>
        </div>
        {/* <div className="flex-1 pr-4">
          {user?.role !== 'ADMIN' && (
            <Button onClick={() => closeDrawer} className="float-right">
              Add Invoice
              <PlusCircleOutlined />
            </Button>
          )}
        </div> */}
      </div>
      <div className="flex m-4 mt-6 p-4 rounded-lg bg-gradient-primary text-white flex flex-col justify-between">
        <p className="text-base font-thin">Outstanding</p>
        <div className="flex justify-between text-lg -mt-6">
          <div>
            <p className="text-3xl">
              <span className="font-thin">{outstandingAmount()}</span>
            </p>
          </div>
        </div>
        <div className=" flex justify-between">
          <div className="text-sm font-thin">Outstanding Invoices: {outstandingNumber()}</div>
        </div>
      </div>
      <ScrollView>
        {invoices.map(i => (
          <InvoiceCard invoice={i} />
        ))}
      </ScrollView>
    </Content>
  )
}

export default Invoices
