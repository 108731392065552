import * as React from 'react'

function SvgCoaches(props) {
  return (
    <svg id="coaches_svg__Layer_1" x={0} y={0} viewBox="0 0 317.9 195" xmlSpace="preserve" {...props}>
      <style />
      <path d="M159.4 172c-32 0-58-26-58-58 0-2.9.2-5.8.6-8.6l14.8 2.2c-.3 2.1-.5 4.3-.5 6.4 0 23.7 19.3 43 43 43s43-19.3 43-43c0-2.1-.2-4.3-.5-6.4l14.8-2.2c.4 2.8.6 5.7.6 8.6.2 32-25.8 58-57.8 58z" />
      <path d="M241.5 195H77.3c-4.1 0-7.5-3.4-7.5-7.5 0-.4 0-.8.1-1.1-.1-.8-.1-1.6-.1-2.4 0-14.8 9.4-28.4 26.6-38.5 3.6-2.1 8.2-.9 10.3 2.7 2.1 3.6.9 8.2-2.7 10.3-10.6 6.2-17.1 13.8-18.7 21.6h148.4c-1.6-7.8-8.2-15.4-18.7-21.6-3.6-2.1-4.8-6.7-2.7-10.3 2.1-3.6 6.7-4.8 10.3-2.7 17.1 10.1 26.6 23.7 26.6 38.5 0 .8 0 1.6-.1 2.4.1.4.1.8.1 1.1-.2 4.1-3.6 7.5-7.7 7.5zM211.6 89.9H107.2c-2.2 0-4.3-1-5.7-2.7-1.4-1.7-2-3.9-1.7-6.1 2.4-14 9.7-26.9 20.6-36.1 10.9-9.3 24.8-14.3 39-14.3s28.1 5.1 39 14.3c10.9 9.3 18.3 22.1 20.6 36.1.4 2.2-.2 4.4-1.7 6.1-1.4 1.8-3.4 2.7-5.7 2.7zm-94.6-15h84.9c-6.7-17.1-23.6-29.2-42.4-29.2-19 0-35.9 12.1-42.5 29.2zM246.3 113.6c-5.6 0-11.2-1-16.4-3l5.2-14.1c3.6 1.3 7.3 2 11.2 2 17.6 0 31.9-14.3 31.9-31.9 0-1.4-.1-2.7-.3-4.1l14.9-1.9c.2 2 .4 4 .4 5.9 0 26.1-21 47.1-46.9 47.1zM310.4 153.1h-68.9c-4.1 0-7.5-3.4-7.5-7.5s3.4-7.5 7.5-7.5h61.3c-.4-9.7-3.4-19.2-8.5-27.4-2.2-3.5-1.1-8.1 2.4-10.3 3.5-2.2 8.1-1.1 10.3 2.4 7.1 11.4 10.9 24.5 10.9 37.9 0 1.2 0 2.5-.1 3.7.1.4.1.8.1 1.2 0 4.1-3.4 7.5-7.5 7.5zM287.1 49.5h-63.6v-15h53.4C271.4 22.9 259.5 15 246.3 15c-10 0-19.5 4.5-26 12.3l-11.5-9.6C218.1 6.4 231.8 0 246.3 0c24 0 44.2 17.1 48.2 40.8.4 2.2-.2 4.4-1.7 6.1-1.4 1.6-3.5 2.6-5.7 2.6zM71.6 113.6c-25.9 0-46.9-21.1-46.9-46.9 0-2 .1-4 .4-5.9L40 62.7c-.2 1.3-.3 2.7-.3 4.1 0 17.6 14.3 31.9 31.9 31.9 3.8 0 7.6-.7 11.2-2l5.2 14.1c-5.2 1.8-10.8 2.8-16.4 2.8zM76.4 153.1H7.5c-4.1 0-7.5-3.4-7.5-7.5 0-.4 0-.8.1-1.2-.1-1.3-.1-2.5-.1-3.8 0-13.5 3.8-26.6 10.9-37.9 2.2-3.5 6.8-4.6 10.3-2.4 3.5 2.2 4.6 6.8 2.4 10.3-5.2 8.2-8.1 17.7-8.5 27.4h61.3c4.1 0 7.5 3.4 7.5 7.5s-3.4 7.6-7.5 7.6zM94.4 49.5H30.8c-2.2 0-4.3-1-5.7-2.7-1.4-1.7-2-3.9-1.7-6.1C27.4 17.1 47.6 0 71.6 0c14.5 0 28.2 6.4 37.5 17.7l-11.5 9.6c-6.5-7.8-16-12.3-26-12.3-13.2 0-25.1 7.9-30.6 19.5h53.4v15z" />
    </svg>
  )
}

export default SvgCoaches
