import * as React from 'react'

const More = props => {
  return (
    <svg id="more_svg__Layer_1" x={0} y={0} viewBox="0 0 81.3 267" xmlSpace="preserve" {...props}>
      <style />
      <path d="M40.7 81.3C18.2 81.3 0 63.1 0 40.7S18.2 0 40.7 0s40.7 18.2 40.7 40.7-18.3 40.6-40.7 40.6zm0-66.3C26.5 15 15 26.5 15 40.7c0 14.1 11.5 25.7 25.7 25.7s25.7-11.5 25.7-25.7C66.3 26.5 54.8 15 40.7 15zM40.7 174.2C18.2 174.2 0 155.9 0 133.5s18.2-40.7 40.7-40.7 40.7 18.2 40.7 40.7-18.3 40.7-40.7 40.7zm0-66.4c-14.1 0-25.7 11.5-25.7 25.7s11.5 25.7 25.7 25.7 25.7-11.5 25.7-25.7-11.6-25.7-25.7-25.7zM40.7 267C18.2 267 0 248.8 0 226.3s18.2-40.7 40.7-40.7 40.7 18.2 40.7 40.7S63.1 267 40.7 267zm0-66.3c-14.1 0-25.7 11.5-25.7 25.7 0 14.1 11.5 25.7 25.7 25.7s25.7-11.5 25.7-25.7c-.1-14.2-11.6-25.7-25.7-25.7z" />
    </svg>
  )
}

export default More
