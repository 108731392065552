import React from 'react'
import { useHistory } from 'react-router-dom'
import { CAIcon } from './Chessadmin/Icon/CAIcon'

interface Props {
  title: string
  subtitle?: string
  showBackButton?: boolean
}

export const ActionBar: React.FC<Props> = props => {
  const history = useHistory()
  return (
    <div className="flex items-center justify-between px-4 pt-4">
      <div className="flex items-center">
        {props.showBackButton !== false && (
          <div className="mr-2">
            <CAIcon type="arrow-left" onClick={() => history.goBack()} />
          </div>
        )}
        <div>
          <div className="text-xl leading-4">{props.title}</div>
          {props.subtitle !== undefined && <div className="text-xs">{props.subtitle}</div>}
        </div>
      </div>
      <div>{React.Children.toArray(props.children)}</div>
    </div>
  )
}
