import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Input } from 'antd'
import Student from './../types/Student'
import Content, { ScrollView } from './Content'
import { Toolbar } from './Toolbar'
import StudentCard from './students/StudentCard'
import useSortable from '../hooks/sortableHook'
import useSearchable from '../hooks/searchableHook'
import SortButton, { SortOrder } from './SortButton'
import { userWithInfo } from '../utils/FormattingUtils'
import { useHistory } from 'react-router-dom'
import { Checkable } from '../types/Checkable'

interface Props {
  data: Student[]
  actions: JSX.Element[]
  checkable?: boolean
  onCheckListChange?: (checked: Student[]) => void
  showDeleteOption?: boolean
  onDelete?: (student: Student) => void
}

const StudentList: React.FC<Props> = props => {
  const history = useHistory()
  const actions = [
    <SortButton
      key="student-sort"
      options={{ serialNo: 'Student Id', fullName: 'Student Name' }}
      onChange={(by, order) => setSort({ by, order })}
    />,
    ...props.actions
  ]

  const dataWithInfo = useMemo(() => {
    return props.data.map(s => {
      return {
        ...userWithInfo(s)
      }
    })
  }, [props.data])

  const [checkableStudents, setCheckableStudents] = useState<Checkable<Student>[]>([])

  useEffect(() => {
    setCheckableStudents(
      dataWithInfo.map(s => {
        return {
          ...s,
          checked: false
        }
      })
    )
  }, [dataWithInfo])

  const searchableStudents = useSearchable(checkableStudents, s => [s.fullName, s.username])

  const [sort, setSort] = useState({ by: 'serialNo', order: 'asc' as SortOrder })

  const sortableStudents = useSortable(searchableStudents.data, [sort.by], [sort.order])

  const handleCheckChange = (checked: boolean, id: string) => {
    if (props.checkable) {
      const newCheckableStudents = checkableStudents.map(v =>
        id === v.uuid
          ? {
              ...v,
              checked
            }
          : v
      )
      setCheckableStudents(newCheckableStudents)
      if (props.onCheckListChange !== undefined) {
        props.onCheckListChange(newCheckableStudents.filter(s => s.checked))
      }
    }
  }

  const handleOnClick = useCallback(
    s => {
      history.push(`/students/${s.uuid}/billing`)
    },
    [history]
  )

  const handleOnDelete = useCallback(student => props.onDelete && props.onDelete(student), [props])

  return (
    <Content>
      <Toolbar actions={actions}>
        <Input.Search placeholder="Search students" className="w-full" {...searchableStudents.input} />
      </Toolbar>
      <ScrollView className="p-4">
        {sortableStudents.map(s => (
          <StudentCard
            key={`student-${s.uuid}`}
            student={s}
            checkVisible={props.checkable === true}
            checked={s.checked}
            onChange={checked => handleCheckChange(checked, s.uuid)}
            onClick={handleOnClick}
            showDelete={props.showDeleteOption}
            onDelete={handleOnDelete}
          />
        ))}
      </ScrollView>
    </Content>
  )
}

StudentList.defaultProps = {
  showDeleteOption: false
}

export default StudentList
