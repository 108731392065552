import React from 'react'
import { Drawer } from 'antd'
import './index.scss'
import TextLoop from 'react-text-loop'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/store'

type DrawerProps = {
  visible: boolean
  onClose: () => void
}

const SidePanel: React.FC<DrawerProps> = props => {
  const { visible, onClose } = props
  // const firstname = useSelector((state: AppState) => state.user.firstname || '')
  const { firstname, lastname } = useSelector((state: AppState) => state.user.user)
  return (
    <div>
      <Drawer placement="left" closable={false} keyboard={true} maskClosable={true} onClose={onClose} visible={visible}>
        <div className="flex flex-col h-full">
          <div className="flex-none">
            <p className="pt-8 text-lg text-primary-blue">Hey, {firstname + ' ' + lastname}</p>
          </div>
          <div className="flex-1 pt-8">
            <Link to={'/me'}>
              <p className="text-sm text-primary-blue">Profile</p>
            </Link>
            <Link to={'/home'}>
              <p className="text-sm text-primary-blue">Home</p>
            </Link>
            <Link to={'/students'}>
              <p className="text-sm text-primary-blue">Students</p>
            </Link>
            <Link to={'/coaches'}>
              <p className="text-sm text-primary-blue">Coaches</p>
            </Link>
            <Link to={'/packs'}>
              <p className="text-sm text-primary-blue">Packs</p>
            </Link>
          </div>
          <div className="flex-none">
            <p className="text-primary-blue">Made with ♥ remotely</p>
            <div className="-mt-4 text-primary-blue">
              from{' '}
              <span>
                <TextLoop interval={2000}>
                  <span>Chennai, IN</span>
                  <span>Hyderbad, IN</span>
                  <span>Bangalore, IN</span>
                  <span>Singapore</span>
                  <span>Berlin, Germany</span>
                  <span>Nigeria</span>
                </TextLoop>
              </span>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default SidePanel
