import React, { useCallback, useMemo, useEffect } from 'react'
import { Select, DatePicker, Checkbox, Form, Col, Row, Button } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store/store'

import _ from 'lodash'
import BottomSheet from '../../BottomSheet'

export enum ClassType {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Group = 'GROUP',
  Individual = 'INDIVIDUAL'
}

export interface Filter {
  coaches: string[]
  students: string[]
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  classTypes: string[]
  touched: boolean
}

interface Props {
  visible: boolean
  onCancel: () => void
  value: Filter
  onChange: (filter: Filter) => void
}

const ClassFilter: React.FC<Props> = props => {
  const coachesData = useSelector((state: AppState) => state.coach.data)
  const studentsData = useSelector((state: AppState) => state.students.data)

  const [form] = Form.useForm()

  useEffect(() => {
    if (!props.value.touched) {
      form.resetFields()
    }
  }, [form, props.value.touched])

  const classTypeOptions = useMemo(
    () =>
      _.toPairs(ClassType).map(v => {
        return {
          label: v[0],
          value: v[1]
        }
      }),
    []
  )

  const handleEndDateDisabled = useCallback(
    d => {
      const startDate = form.getFieldValue('startDate')
      if (startDate == null) {
        return false
      }
      return d?.isBefore(startDate.value || undefined) || false
    },
    [form]
  )

  const handleOk = useCallback(() => {
    form.validateFields().then((values: any) => {
      props.onChange({ ...values, touched: true })
      props.onCancel()
    })
  }, [form, props])

  const filterStudents = useCallback((inputValue, option) => {
    const regex = new RegExp(inputValue, 'i')
    return regex.test(option.children)
  }, [])

  return (
    <BottomSheet value={props.visible} onChange={props.onCancel}>
      <Form form={form} initialValues={props.value} onFinish={handleOk} className="p-4">
        <Form.Item label="Coaches" name="coaches">
          <Select mode="multiple" className="w-full" placeholder="Select coaches">
            {coachesData.map(c => (
              <Select.Option value={c.uuid} key={`coach-select-${c.uuid}`}>
                {c.fullName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Students" name="students">
          <Select mode="multiple" className="w-full" placeholder="Select students" filterOption={filterStudents}>
            {studentsData.map(c => (
              <Select.Option value={c.uuid} key={`student-select-${c.uuid}`}>
                {c.fullName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label="Start" name="startDate">
              <DatePicker placeholder="Start date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End" name="endDate">
              <DatePicker placeholder="End date" disabledDate={handleEndDateDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Class type" name="classTypes">
          <Checkbox.Group options={classTypeOptions} />
        </Form.Item>
        <Button block type="primary" htmlType="submit">
          Apply
        </Button>
      </Form>
    </BottomSheet>
  )
}

export default ClassFilter
