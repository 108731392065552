import React from 'react'
import '@ant-design/compatible/assets/index.css'
import { Typography, Input, Button, Form } from 'antd'
import academySlice from '../store/academySlice'
import { useHistory } from 'react-router-dom'
import useAcademy from '../hooks/academyHook'
import Academy from '../types/Academy'
import { useDispatch } from 'react-redux'

const { Title } = Typography

const EditAcademy: React.FC = props => {
  return (
    <div className="m-4">
      <Title>Edit Academy</Title>
      <EditAcademyForm />
    </div>
  )
}

const EditAcademyForm = () => {
  const academy = useAcademy() as Academy
  const dispatch = useDispatch()
  const history = useHistory()

  const handleOnFinish = (values: any) => {
    dispatch(academySlice.actions.update(values))
    history.push('/academy')
  }

  return (
    <Form onFinish={handleOnFinish} initialValues={academy}>
      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input academy name!' }]}>
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please input academy address!' }]}>
        <Input placeholder="Address" />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Save
        </Button>
        <Button block onClick={() => history.push('/academy')}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  )
}

export default EditAcademy
