import React, { useState, useCallback } from 'react'
import Content, { ScrollView } from '../../components/Content'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Input, message } from 'antd'
import Group from '../../types/Group'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState, useThunkDispatch } from '../../store/store'
import CreateGroupSheet from '../../components/groups/CreateGroupSheet'
import StudentsGroupsNav from '../../components/students/StudentsGroupsNav'
import { Toolbar } from '../../components/Toolbar'
import GroupCard from '../../components/groups/GroupCard'
import SortButton, { SortOrder } from '../../components/SortButton'
import useSearchable from '../../hooks/searchableHook'
import useSortable from '../../hooks/sortableHook'
import { deleteGroup } from '../../store/thunks'

const Groups: React.FC = () => {
  const thunkDispatch = useThunkDispatch()
  const groups = useSelector((state: AppState) => state.groups.data)

  const [createGroupSheetVisible, setCreateGroupSheetVisible] = useState(false)

  const searchableGroups = useSearchable(groups, g => [g.name])

  const [sort, setSort] = useState({ by: 'name', order: 'asc' as SortOrder })

  const sortableStudents = useSortable(searchableGroups.data, [sort.by], [sort.order])

  // const handleGroupEdit = (group: Group) => {
  //   setCreateGroupSheetVisible(true)
  //   dispatch(
  //     createGroupSlice.actions.update({
  //       id: group.id,
  //       name: group.name,
  //       description: group.description,
  //       studentIds: group.students.map(s => s.uuid)
  //     })
  //   )
  // }

  const actions = [
    <SortButton
      key="group-sort"
      options={{ id: 'Group Id', name: 'Group name', studentCount: 'Student Count' }}
      onChange={(by, order) => setSort({ by, order })}
    />,
    <Button
      icon={<PlusCircleOutlined />}
      className="ml-1"
      key="groups-add"
      onClick={() => setCreateGroupSheetVisible(true)}
    />
  ]

  const handleOnDelete = useCallback(
    async group => {
      try {
        await thunkDispatch(deleteGroup(group.id))
        message.success('Group deleted successfully!')
      } catch {
        message.error('Error occured while creating group.')
      }
    },
    [thunkDispatch]
  )

  return (
    <Content className="pt-8 px-2">
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          {/* <CAIcon type="menu" height="10px" onClick={openDrawer} /> */}
          <span className="pt-4 text-lg text-primary-blue">
            Students <span className="text-sm text-primary-grey">/ Groups</span>
          </span>
        </div>
      </div>
      <StudentsGroupsNav />
      <Toolbar actions={actions}>
        <Input.Search placeholder="Search groups" {...searchableGroups.input} />
      </Toolbar>
      <ScrollView className="p-4">
        {sortableStudents.map((group: Group) => (
          <Link key={`group-${group.id}`} to={`/groups/${group.id}`}>
            <GroupCard key={group.id} group={group} onDelete={handleOnDelete} />
          </Link>
        ))}
      </ScrollView>
      <CreateGroupSheet visible={createGroupSheetVisible} onCancel={() => setCreateGroupSheetVisible(false)} />
    </Content>
  )
}

export default Groups
