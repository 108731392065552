import React, { useState, useMemo, useCallback } from 'react'
import { CloseOutlined, PlusCircleOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Input, Button } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import ClassCard from '../../components/classes/ClassCard'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/store'
import Content, { ScrollView } from '../../components/Content'
import { Toolbar } from '../../components/Toolbar'
import ClassFilter, { Filter, ClassType } from '../../components/classes/modals/ClassFilter'
import useSearchable from '../../hooks/searchableHook'
import useSortable from '../../hooks/sortableHook'
import moment from 'moment'
import _ from 'lodash'
import SortButton, { SortOrder } from '../../components/SortButton'
import useValueInput from '../../hooks/valueInputHook'

const Classes: React.FC = () => {
  const history = useHistory()

  const classLogs = useSelector((state: AppState) => state.classLog.data)

  const searchableClasses = useSearchable(classLogs, c => [c.coach.fullName, c.coach.username])

  const [filterSheetVisible, setFilterSheetVisible] = useState(false)

  const initialFilter: Filter = useMemo(() => {
    return {
      coaches: [],
      students: [],
      startDate: null,
      endDate: null,
      classTypes: [],
      touched: false
    }
  }, [])

  const filter = useValueInput<Filter>(initialFilter)

  const filterableClasses = useMemo(() => {
    const { coaches, startDate, endDate, classTypes, students } = filter.value

    let classes = searchableClasses.data

    if (startDate != null) {
      const startDateMoment = moment(startDate)
      classes = classes.filter(c => moment(c.happenedAt).isSameOrAfter(startDateMoment))
    }

    if (endDate != null) {
      const endDateMoment = moment(endDate)
      classes = classes.filter(c => moment(c.happenedAt).isSameOrBefore(endDateMoment))
    }

    if (!_.isEmpty(coaches)) {
      classes = classes.filter(c => coaches.includes(c.coach.uuid))
    }

    if (!_.isEmpty(students)) {
      classes = classes.filter(c => {
        const classStudentIds = c.students.map(s => s.studentId)
        return students.reduce<boolean>((pv, cv) => pv || classStudentIds.includes(cv), false)
      })
    }

    const hasOfflineFilter = classTypes.includes(ClassType.Offline)
    const hasOnlineFilter = classTypes.includes(ClassType.Online)
    const hasIndividualFilter = classTypes.includes(ClassType.Individual)
    // const hasGroupFilter = classTypes.includes(ClassType.Group)

    classes = classes.filter(c => {
      if (_.isEmpty(classTypes)) {
        return true
      }

      if (hasOfflineFilter && c.type === ClassType.Offline) {
        return true
      }

      if (hasOnlineFilter && c.type === ClassType.Online) {
        return true
      }

      if (hasIndividualFilter && _.size(c.students) === 1) {
        return true
      }

      return false
    })

    if (classTypes.includes(ClassType.Offline)) {
      classes = classes.filter(c => c.type === ClassType.Offline)
    }

    return classes
  }, [searchableClasses.data, filter.value])

  const [sort, setSort] = useState({ by: 'happenedAt', order: 'desc' as SortOrder })

  const sortableClasses = useSortable(filterableClasses, [sort.by], [sort.order])

  const resetFilter = useCallback(() => filter.onChange(initialFilter), [filter, initialFilter])

  return (
    <Content className="pt-8 px-2">
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          <span className="pt-4 text-lg text-primary-blue">Classes</span>
        </div>
        <div className="flex-1 pr-4">
          <Button onClick={() => history.push('/classes/create')} className="float-right">
            Add Class
            <PlusCircleOutlined />
          </Button>
        </div>
      </div>

      <Toolbar
        actions={[
          <SortButton
            defaultSortOrder={sort.order}
            options={{ happenedAt: 'Date' }}
            onChange={(by, order) => setSort({ by, order })}
            key="classes-sort-by"
          />
        ]}
      >
        <Input.Search className="w-full" {...searchableClasses.input} />
      </Toolbar>

      <div className="flex items-center px-4 pt-2">
        <span className="mr-1">Filters:</span>
        <Button
          icon={filter.value.touched ? <EditOutlined /> : <PlusOutlined />}
          onClick={() => setFilterSheetVisible(!filterSheetVisible)}
        />
        {filter.value.touched && <Button className="ml-2" icon={<CloseOutlined />} onClick={resetFilter} />}
      </div>

      <ScrollView className="p-4">
        {sortableClasses.map(cl => (
          <Link key={`class-${cl.id}`} to={`/classes/${cl.id}/attendance`}>
            <ClassCard key={`class-${cl.id}`} classObj={cl} />
          </Link>
        ))}
      </ScrollView>
      <ClassFilter visible={filterSheetVisible} onCancel={() => setFilterSheetVisible(false)} {...filter} />
    </Content>
  )
}

export default Classes
