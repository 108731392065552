import React from 'react'
import Pack, { PackType } from '../../types/Pack'

interface Props {
  pack: Pack
}

const PackCard: React.FC<Props> = props => {
  const { name, type, value, price, students } = props.pack

  return (
    <div className="flex flex-col bg-gradient-primary text-white mb-4 p-4 rounded-lg">
      <div className="flex justify-between mb-4">
        <div>
          <div className="text-lg">{name}</div>
          <div className="text-sm">
            {type === PackType.PERIODIC && <div>Periodic, {value} Months</div>}
            {type === PackType.PAY_PER_CLASS && <div>Pay per class, {value} Classes</div>}
          </div>
        </div>
        <div className="text-right">
          <div className="text-2xl font-bold">{students?.length || 0}</div>
          <div className="text-xs">enrolled students</div>
        </div>
      </div>
      <div className="text-2xl text-right">INR {price.toFixed(2)}</div>
    </div>
  )
}

export default PackCard
