import React, { useEffect } from 'react'
import Content from '../../components/Content'
import { CAIcon } from '../../components/Chessadmin/Icon/CAIcon'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { AppState } from '../../store/store'
import { Divider } from 'antd'
import CAButton from '../../components/Chessadmin/Button'

const InvoicesSelect: React.FC = () => {
  const history = useHistory()
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const invoices = useSelector((state: AppState) => state.invoice.data)
  const selectInvoice = invoices.find(x => x.id === invoiceId)
  const pack = selectInvoice?.pack || null
  let taxes = 0
  if (pack && pack.price) {
    taxes = 0.18 * pack.price
  }
  const grandTotal = () => {
    if (pack && pack.price) {
      return pack.price
    } else {
      return ''
    }
  }
  return (
    <div className="flex flex-col h-screen justify-between mt-8 mx-3">
      <div className="flex flex-col pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          <span>
            <CAIcon type="arrow-left" height="15px" onClick={() => history.goBack()} />
          </span>
          <span className="pl-2 text-lg text-primary-blue">Invoice</span>
        </div>
      </div>
      {/* <div className="flex px-4 pt-8">
        <p className="text-lg">{pack?.name}</p>
      </div> */}
      <div className="flex flex-1 flex-col px-4 pt-16">
        <div className="flex">
          <div className="flex-1 text-overflow border-b">
            <p className="text-lg">{pack?.name}</p>
          </div>
          <div className="flex-1 text-overflow border-b">
            <p className="float-right text-lg">{pack?.price}</p>
          </div>
        </div>
        {/* <div className="flex text-overflow border-b">
          <p className="text-xs text-primary-grey float-right">Taxes and other Charges {taxes}</p>
        </div> */}
        <div className="flex">
          <Divider type="horizontal" />
        </div>
        <div className="flex flex-1 flex-row">
          <div className="flex-initial text-overflow border-b">
            <p className="text-lg">Grand Total</p>
          </div>
          <div className="flex-initial text-overflow border-b">
            <p className="float-right text-lg">{grandTotal()}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end px-4 pb-32">
        <div className="flex-1 w-full">
          <CAButton type="primary" text="Settle" onClick={() => history.push('/billing/invoices')} />
        </div>
      </div>
    </div>
  )
}

export default InvoicesSelect
