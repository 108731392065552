import * as React from 'react'

function SvgBilling(props) {
  return (
    <svg id="billing_svg__Layer_1" x={0} y={0} viewBox="0 0 250.8 274.4" xmlSpace="preserve" {...props}>
      <style />
      <path d="M249 43.5c-.8-1.4-2.1-2.5-3.7-2.9l-141-39.3c-3.3-.9-6.7 1-7.6 4.3L87.4 39l-33.6-4.9c-1.6-.2-3.3.2-4.6 1.2-1.3 1-2.2 2.4-2.4 4l-4.5 31H7.1C3.7 70.3 1 73 1 76.4v190.7c0 3.4 2.8 6.2 6.2 6.2h146.4c3.4 0 6.2-2.8 6.2-6.2v-12.6l10 1.4c.3 0 .6.1.9.1 3 0 5.6-2.2 6.1-5.3l2.6-17.8 11.6 3.2c.6.2 1.1.2 1.7.2 2.7 0 5.2-1.8 5.9-4.5l51.2-183.7c.2-1.5 0-3.2-.8-4.6zM13.3 82.6h23V261h-23V82.6zM147.4 261H65.8V82.6h81.7V261zm17.9-18.1l-5.6-.8V76.4c0-3.4-2.8-6.2-6.2-6.2H54.8L58.1 47l132.7 19.2-25.5 176.7zm22.9-20.2l-7.2-2L203.9 62c.5-3.4-1.8-6.5-5.2-7l-99-14.3 7.2-25.9 129.2 36-47.9 171.9z" />
    </svg>
  )
}

export default SvgBilling
