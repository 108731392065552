import * as React from 'react'

function SvgUser(props) {
  return (
    <svg id="user_svg__Layer_1" x={0} y={0} viewBox="0 0 540 527" xmlSpace="preserve" {...props}>
      <style>{'.user_svg__st1{fill-rule:evenodd;clip-rule:evenodd;fill:#ffd7a9}'}</style>
      <path
        d="M270 0c149 0 270 118.1 270 263.5S419 527 270 527 0 408.9 0 263.5 121 0 270 0z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#e4ecf9"
      />
      <path
        className="user_svg__st1"
        d="M202.5 315.5c.8 8.5-1.2 14.3-2.7 17.5-4.2 8.8-12.3 13.7-25.3 21.6-3.9 2.4-7.2 4.2-9.5 5.4 6.8 8.9 16 21 27.2 34.9 14 17.5 28.9 35 32.3 35.1 6.7.2 66.2-89.3 70.3-96 4.1-6.7-55.1-10.9-44.3-43s-48 24.5-48 24.5z"
      />
      <defs>
        <path
          id="user_svg__SVGID_3_"
          d="M270 0c149 0 270 118.1 270 263.5S419 527 270 527 0 408.9 0 263.5 121 0 270 0z"
        />
      </defs>
      <clipPath id="user_svg__SVGID_2_">
        <use xlinkHref="#user_svg__SVGID_3_" overflow="visible" />
      </clipPath>
      <path
        d="M152.5 183s-54.8-109.9 108-61c0 0 92-37.9 83.5 92s92.4 122.7 88 165.5c-4.4 42.8-19.5 50.5-19.5 50.5l-273-49.5s47.2-61 41-104.5c-6.2-43.5-25.3-87.5-28-93z"
        clipPath="url(#user_svg__SVGID_2_)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#050000"
      />
      <path
        d="M466 444.6C427.6 377 355.6 331.5 273.4 331.5c-84 0-157.2 47.5-195 117.5 48.9 48.2 116.8 78 191.7 78 77.1 0 146.7-31.7 195.9-82.4z"
        fill="#faf4e5"
      />
      <path
        className="user_svg__st1"
        d="M228.5 158.5c47.4 3.6 60 35.2 50 85.5-9.4 47.1-29.1 79.5-63 87-23 5.1-29.3 23.2-50.5-34-7.6-20.5-6.4-67.8 0-95 9-38.1 44.7-44.9 63.5-43.5z"
      />
      <path
        className="user_svg__st1"
        d="M287.9 233.6c6.9 3.1 8.9 13.6 4.5 23.4-4.4 9.8-13.7 15.1-20.6 12-6.9-3.1-8.9-13.6-4.5-23.4 4.5-9.7 13.7-15.1 20.6-12zM202.5 315.5c.8 8.5-1.2 14.3-2.7 17.5-4.2 8.8-12.3 13.7-25.3 21.6-3.9 2.4-7.2 4.2-9.5 5.4 6.8 8.9 16 21 27.2 34.9 14 17.5 28.9 35 32.3 35.1 6.7.2 66.2-89.3 70.3-96 4.1-6.7-55.1-10.9-44.3-43s-48 24.5-48 24.5z"
      />
      <g>
        <path
          d="M201 350.5c6-1 10.2-3.3 15-5.8 6.6-3.5 11.2-7.1 13.1-8.8 5.3-4.5 8.4-8.5 14.6-16.5 1.6-2.1 6.7-8.7 6.2-9.2-.7-.6-7.7 9.7-20.9 16-4.2 2-8.7 3.6-13.5 4.6-7.5 1.7-13.2 4.7-18.2 6.1-3.3 4.6-8 8.2-14.4 12.3 8.1 2.5 14.6 1.9 18.1 1.3z"
          fill="#f7b472"
        />
      </g>
    </svg>
  )
}

export default SvgUser
