import { AppState } from '../store/store'
import { useSelector } from 'react-redux'

const useAcademy = () => {
  const { data, selectedAcademyIndex } = useSelector((state: AppState) => state.academy)

  if (!data || data.length === 0) {
    return null
  }

  return data[selectedAcademyIndex]
}

export default useAcademy
