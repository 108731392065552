import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Row, Col, Typography, Button } from 'antd'
import { Link } from 'react-router-dom'
import useAcademy from '../hooks/academyHook'
import * as AcademyType from '../types/Academy'

const { Title } = Typography

const Academy: React.FC = () => {
  const academy = useAcademy() as AcademyType.default

  return (
    <div className="m-4">
      <Link to="/academy/edit">
        <Button type="primary" className="block ml-auto">
          <EditOutlined />
          Edit
        </Button>
      </Link>

      <Title className="m-0" level={2}>
        {academy.name}
      </Title>

      <Row className="mb-4">
        <Col>
          <Title level={4}>Academy shortname</Title>
        </Col>
        <Col>{academy.shortName}</Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Title level={4}>Address Line 1</Title>
        </Col>
        <Col>{academy.address}</Col>
      </Row>
    </div>
  )
}

export default Academy
