import React from 'react'
import { Menu } from 'antd'
import { useHistory } from 'react-router-dom'

const StudentsGroupsNav: React.FC = () => {
  const history = useHistory()
  const menus = [
    {
      key: 'item-route-students',
      path: '/students',
      title: 'Individual'
    },
    {
      key: 'item-route-groups',
      path: '/groups',
      title: 'Groups'
    }
  ]

  let selectedKey = ''

  menus.forEach(m => {
    if (history.location.pathname === m.path) {
      selectedKey = m.key
    }
  })

  return (
    <Menu
      className="flex"
      mode="horizontal"
      defaultSelectedKeys={[selectedKey]}
      onClick={({ item }) => history.push(item.node.dataset.path)}
    >
      {menus.map(m => (
        <Menu.Item className="flex-1 text-center" key={m.key} data-path={m.path}>
          {m.title}
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default StudentsGroupsNav
