import React from 'react'
import CAButton from '../../components/Chessadmin/Button/index'
import './index.scss'
import { useHistory } from 'react-router-dom'

const Landing = () => {
  const history = useHistory()
  return (
    <div className="flex flex-col h-screen justify-between">
      <div className="flex flex-col background-img" />
      <div className="bg-primary-light-blue flex-col">
        <div>
          <p className="text-3xl leading-none px-8 pt-16 text-primary-blue">Hey Coach !</p>
        </div>
        <div className="pl-8 pr-16">
          <p className="text-base leading-none text-primary-blue">
            It’s time to kickstart by creating you chess academy.
          </p>
        </div>
        {/* <div className="mx-8 mt-4 mb-8 float-right"> */}
        {/*<p className="text-base float-right px-8 pt-24 text-primary-blue align-bottom">Next</p>*/}
        {/*<Button type="primary" text="Next" onClick={() => handlePageChange(1)} />*/}
        {/* <Icon name="arrow-right" height="25px" onClick={() => handlePageChange(1)} /> */}
        {/* <ArrowRightIcon onClick={() => handlePageChange(1)} /> */}
        {/* </div> */}
        {/* <div className="mx-8 mt-8 mb-8">
          <CAButton type="transparent" text="Login" onClick={() => formOnboard.submit} />
        </div> */}
        <div className="flex mx-6 pt-2 mb-8">
          <div className="w-1/2 mx-2">
            <CAButton type="transparent" text="Signup" onClick={() => history.push('/signup')} />
          </div>
          <div className="w-1/2 mx-2">
            <CAButton type="primary" text="Login" onClick={() => history.push('/login')} icon="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
