import React from 'react'
import Invoice from '../../../types/Invoice'
import { PackType } from '../../../types/Pack'
import { useHistory } from 'react-router-dom'

const InvoiceCard: React.FC<{ invoice: Invoice }> = props => {
  const { pack, student, consumed, id } = props.invoice
  const history = useHistory()
  return (
    <div
      className="flex-1 m-4 p-4 rounded-lg bg-primary-light-blue text-primary-blue flex flex-col justify-between"
      onClick={() => history.push('/billing/invoices/' + id)}
    >
      <span>{consumed} Classes</span>
      <div className="flex justify-between text-lg">
        <div>{pack?.name}</div>
        <div>INR {pack?.price}</div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="text-sm">{student?.fullName}</div>
        <div className="text-xs">{pack?.type === PackType.PAY_PER_CLASS ? 'Pay per class' : 'Periodic'}</div>
      </div>
    </div>
  )
}

export default InvoiceCard
