import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory, Redirect } from 'react-router-dom'
import EditAcademy from './pages/EditAcademy'
import Login from './pages/Login'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from './store/store'
import userSlice from './store/userSlice'
import Students from './pages/students/Students'
import Coaches from './pages/coaches/Coaches'
import CoachStudents from './pages/coaches/CoachStudents'
import {
  getStudents,
  getGroups,
  getClasses,
  getCoaches,
  getAcademies,
  getPacks,
  getInvoices,
  getAcademyUser
} from './store/thunks'
import GroupStudents from './pages/groups/GroupStudents'
import Profile from './pages/Profile'
import Classes from './pages/classes/Classes'
import CreateClass from './pages/classes/CreateClass'
import TakeAttendance from './pages/classes/TakeAttendance'
import Academy from './pages/Academy'
import Dashboard from './pages/dashboard/Dashboard'
import WithBottomNav from './components/WithBottomNav'
import Groups from './pages/groups/Groups'
import useAcademy from './hooks/academyHook'
import useAccessToken from './hooks/accessTokenHook'
import Packs from './pages/packs/Packs'
import PackStudents from './pages/packs/PackStudents'
import Invoices from './pages/billing/Invoices'
import Landing from './pages/landing'
import Signup from './pages/signup'
import StudentView from './pages/students/StudentView'
import { decode } from 'jwt-simple'
import { updateAxiosAcademyId, updateAxiosAuthHeader } from './config/axios'
import InvoicesSelect from './pages/billing/InvoicesSelect'

const App: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoggedIn, user } = useSelector((state: AppState) => state.user)
  const academy = useAcademy()

  const [loginChecked, setLoginChecked] = useState(false)
  const { accessToken, setAccessToken } = useAccessToken()

  useEffect(() => {
    const checkLogin = async () => {
      if (accessToken != null) {
        updateAxiosAuthHeader(accessToken)
        const jwt = decode(accessToken, '', true)
        dispatch(userSlice.actions.login(jwt.user))
      }
      setLoginChecked(true)
    }
    checkLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      // // ask varun
      // history.push('/')
      dispatch(getAcademies())
    }
  }, [isLoggedIn, dispatch])

  useEffect(() => {
    if (academy !== null) {
      updateAxiosAcademyId(academy.id as string)
      dispatch(getClasses())
      dispatch(getCoaches())
      dispatch(getStudents())
      dispatch(getGroups())
      dispatch(getPacks())
      dispatch(getInvoices())
      dispatch(getAcademyUser(user?.uuid as string))
    }
  }, [academy, accessToken, dispatch, user])

  useEffect(() => {
    if (loginChecked) {
      if (!isLoggedIn) {
        // history.push('/login')
        history.push('/onboard')
      } else if (history.location.pathname === '/login') {
        history.push('/')
      }
    }
  }, [loginChecked, isLoggedIn, history])

  return (
    <Switch>
      <Route path="/onboard">
        <Landing />
      </Route>
      <Route path="/login">
        <Login />
      </Route>

      <Route
        path="/logout"
        render={() => {
          setAccessToken(null)
          dispatch(userSlice.actions.logout)
          return <Redirect to="/onboard" />
        }}
      />

      <Route path="/signup">
        <Signup />
      </Route>

      <Route>
        <WithBottomNav>
          <Switch>
            <Route path="/me">
              <Profile />
            </Route>
            <Route path="/academy/edit">
              <EditAcademy />
            </Route>
            <Route path="/academy">
              <Academy />
            </Route>
            <Route path="/students/:studentId">
              <StudentView />
            </Route>
            <Route path="/students">
              <Students />
            </Route>
            <Route path="/groups/:groupId">
              <GroupStudents />
            </Route>
            <Route path="/groups">
              <Groups />
            </Route>
            <Route path="/coaches/:coachId">
              <CoachStudents />
            </Route>
            <Route path="/coaches">
              <Coaches />
            </Route>
            <Route path="/classes/:classId/attendance">
              <TakeAttendance />
            </Route>
            <Route path="/classes/create">
              <CreateClass />
            </Route>
            <Route path="/classes">
              <Classes />
            </Route>
            <Route path="/packs/:packId">
              <PackStudents />
            </Route>
            <Route path="/packs">
              <Packs />
            </Route>
            <Route path="/billing/invoices/:invoiceId">
              <InvoicesSelect />
            </Route>
            <Route path="/billing/invoices">
              <Invoices />
            </Route>
            <Route>
              {/* <Dashboard /> */}
              <Classes />
            </Route>
          </Switch>
        </WithBottomNav>
      </Route>
    </Switch>
  )
}

export default App
