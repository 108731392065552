import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SwipeableBottomSheet = require('react-swipeable-bottom-sheet')

interface Props {
  value: boolean
  onChange: (value: boolean) => void
}

const BottomSheet: React.FC<Props> = ({ children, value, onChange }) => {
  return (
    <SwipeableBottomSheet
      open={value}
      overflowHeight={0}
      onChange={onChange}
      shadowTip={false}
      topShadow={false}
      bodyStyle={{
        background: 'linear-gradient(149.77deg, #708ebd 8.57%, #4b6098 89.05%)'
      }}
    >
      <div>{children}</div>
    </SwipeableBottomSheet>
  )
}

export default BottomSheet
