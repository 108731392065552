const useAccessToken = () => {
  const key = 'chesspanel-access-token'

  const token = localStorage.getItem(key)

  const setToken = (token: string | null) => {
    if (token == null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, token)
    }
  }

  return { accessToken: token, setAccessToken: setToken }
}

export default useAccessToken
