import { useMemo } from 'react'
import _ from 'lodash'

const useSortable = <T>(list: T[], sortBy: string[], orderBy: (boolean | 'asc' | 'desc')[]) => {
  return useMemo(() => {
    return _.orderBy(list, sortBy, orderBy)
  }, [list, sortBy, orderBy])
}

export default useSortable
