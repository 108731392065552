import React, { useCallback, useState } from 'react'
import { Input, TreeSelect, Checkbox, Form, message } from 'antd'
import { useSelector } from 'react-redux'
import { AppState, useThunkDispatch } from '../../store/store'
import Student from '../../types/Student'
import BottomSheet from '../BottomSheet'
import { createGroup } from '../../store/thunks'
import CAButton from '../Chessadmin/Button'

interface Props {
  visible: boolean
  onCancel: () => void
}

const CreateGroupSheet: React.FC<Props> = props => {
  const students: Student[] = useSelector((state: AppState) => state.students.data)
  const dispatch = useThunkDispatch()
  const [inFlight, setInFlight] = useState(false)

  const treeData = students.map((s: Student) => {
    return {
      title: s.formattedName,
      key: `student-${s.uuid}`,
      value: s.uuid
    }
  })

  const handleOnFinish = useCallback(
    async (values: any) => {
      setInFlight(true)
      try {
        await dispatch(createGroup(values))
        message.success('Group created successfully!')
        props.onCancel()
      } catch (error) {
        message.error('Oops! Something went wrong please try again')
      } finally {
        setInFlight(false)
      }
    },
    [dispatch, props]
  )

  const filterSearchData = useCallback((inputValue, option) => {
    const regex = new RegExp(inputValue, 'i')
    return regex.test(option.title)
  }, [])

  return (
    <BottomSheet value={props.visible} onChange={props.onCancel}>
      <div className="p-4">
        <Form onFinish={handleOnFinish}>
          <Form.Item label="Group name" name="name">
            <Input placeholder="name" type="text" />
          </Form.Item>
          <Form.Item label="School Group" name="isSchool" valuePropName="checked">
            <Checkbox>Is School Group?</Checkbox>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea placeholder="Enter your description here" rows={4} />
          </Form.Item>
          <Form.Item label="Students" name="studentIds">
            <TreeSelect
              className="block w-full"
              treeData={treeData}
              treeCheckable={true}
              placeholder="Please select"
              filterTreeNode={filterSearchData}
            />
          </Form.Item>
          <CAButton text="Create" type="primary" loading={inFlight} onClick={props.onCancel} />
        </Form>
      </div>
    </BottomSheet>
  )
}

export default CreateGroupSheet
