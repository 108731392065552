import * as React from 'react'

function SvgUsermale(props) {
  return (
    <svg id="usermale_svg__Layer_1" x={0} y={0} viewBox="0 0 540 527.9" xmlSpace="preserve" {...props}>
      <style>
        {'.usermale_svg__st8{fill:#ce9052}.usermale_svg__st8,.usermale_svg__st9{fill-rule:evenodd;clip-rule:evenodd}'}
      </style>
      <path
        d="M270 0c149 0 270 118.1 270 263.5S419 527 270 527 0 408.9 0 263.5 121 0 270 0z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#f6e4f7"
      />
      <defs>
        <path
          id="usermale_svg__SVGID_7_"
          d="M270 0c149 0 270 118.1 270 263.5S419 527 270 527 0 408.9 0 263.5 121 0 270 0z"
        />
      </defs>
      <clipPath id="usermale_svg__SVGID_2_">
        <use xlinkHref="#usermale_svg__SVGID_7_" overflow="visible" />
      </clipPath>
      <path
        d="M273.4 331.5c122.7 0 222.4 101.3 222.4 226s-99.6 226-222.4 226S51 682.2 51 557.5s99.6-226 222.4-226z"
        clipPath="url(#usermale_svg__SVGID_2_)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#2e126b"
      />
      <path
        className="usermale_svg__st8"
        d="M215.5 157c34.8 0 63 39 63 87s-29.1 79.5-63 87c-23 5.1-29.3 23.2-50.5-34-7.6-20.5-16.3-62.9-9.5-90 8.2-32.8 41-50 60-50z"
      />
      <path
        className="usermale_svg__st8"
        d="M287.9 233.6c6.9 3.1 8.9 13.6 4.5 23.4-4.4 9.8-13.7 15.1-20.6 12-6.9-3.1-8.9-13.6-4.5-23.4 4.5-9.7 13.7-15.1 20.6-12zM202.5 315.5c.7 8.5-1.3 14.4-2.7 17.5-4.3 9.5-12.3 14.5-20.6 19.8-5.7 3.6-10.7 5.8-14.2 7.2 6.8 8.9 16 21 27.2 34.9 14 17.5 28.9 35 32.3 35.1 6.7.2 66.2-89.3 70.3-96 4.1-6.7-55.1-10.9-44.3-43 10.9-32.1-48 24.5-48 24.5z"
      />
      <path
        className="usermale_svg__st9"
        d="M236 188s-73.1 27.4-82.9 14c-24-32.5 9.7-97.9 102.9-78 55.5 11.8 25.7 92.7 28 108.7 2.4 16.4-46.5-28.2-48-44.7z"
      />
      <path
        className="usermale_svg__st9"
        d="M145.6 172.3s15.5-44 30.5-52c-15 47.9 23.9 11.9 0 29.5-8.2 6.1-30.5 22.5-30.5 22.5z"
      />
      <g>
        <path
          d="M202.3 350.2c6-1 10.2-3.3 15-5.8 6.6-3.5 11.2-7.1 13.1-8.8 5.3-4.5 8.4-8.5 14.6-16.5 1.6-2.1 6.7-8.7 6.2-9.2-.7-.6-7.7 9.7-20.9 16-4.2 2-8.7 3.6-13.5 4.6-7.5 1.7-13.2 4.7-18.2 6.1-3.3 4.6-8 8.2-14.4 12.3 8.2 2.5 14.6 1.9 18.1 1.3z"
          fill="#ad713a"
        />
      </g>
    </svg>
  )
}

export default SvgUsermale
