import { createSlice } from '@reduxjs/toolkit'
import ActionState from '../types/ActionState'

const allocateStudentSlice = createSlice({
  name: 'allocateStudent',
  initialState: {
    actionState: ActionState.INITIAL
  },
  reducers: {
    actionState: (_, action) => {
      return {
        actionState: action.payload
      }
    }
  }
})

export default allocateStudentSlice
