import React, { useState, useMemo, useCallback } from 'react'
import { PlusCircleOutlined, EditOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Input, Select, message, Form } from 'antd'
import { useSelector } from 'react-redux'
import { AppState, useThunkDispatch } from '../../store/store'
import { Link } from 'react-router-dom'
import Content, { ScrollView } from '../../components/Content'
import PackCard from '../../components/packs/PackCard'
import { Toolbar } from '../../components/Toolbar'
import useSortable from '../../hooks/sortableHook'
import BottomSheet from '../../components/BottomSheet'
import { PackType } from '../../types/Pack'
import useValueInput from '../../hooks/valueInputHook'
import useSearchable from '../../hooks/searchableHook'
import SortButton, { SortOrder } from '../../components/SortButton'
import { createPack } from '../../store/thunks'
import useAcademy from '../../hooks/academyHook'
import Academy from '../../types/Academy'
import CAButton from '../../components/Chessadmin/Button'
import PackFilter, { Filter } from './PackFilter'
import _ from 'lodash'

const Packs: React.FC = () => {
  const packs = useSelector((state: AppState) => state.pack.data)
  const addPackSheetVisible = useValueInput(false)
  const [filterSheetVisible, setFilterSheetVisible] = useState(false)

  const [sort, setSort] = useState({ by: 'name', order: 'asc' as SortOrder })

  const searchablePacks = useSearchable(packs, p => [p.name])

  const initialFilter: Filter = useMemo(() => {
    return {
      packTypes: [],
      months: [1, 12],
      classes: [1, 120],
      prices: [500, 15000],
      touched: false
    }
  }, [])

  const filter = useValueInput<Filter>(initialFilter)

  const filterablePacks = useMemo(() => {
    const { packTypes, classes, months, prices } = filter.value

    let packs = searchablePacks.data

    const isPackTypesEmpty = _.isEmpty(packTypes)
    const hasPeriodicFilter = packTypes.includes(PackType.PERIODIC) || isPackTypesEmpty
    const hasPayPerClassFilter = packTypes.includes(PackType.PAY_PER_CLASS) || isPackTypesEmpty

    packs = packs.filter(p => {
      const checks: boolean[] = [
        (hasPeriodicFilter && p.type === PackType.PERIODIC && p.value >= months[0] && p.value <= months[1]) ||
          (hasPayPerClassFilter && p.type === PackType.PAY_PER_CLASS && p.value >= classes[0] && p.value <= classes[1]),
        p.price >= prices[0] && p.price <= prices[1]
      ]

      return checks.reduce((pv, cv) => pv && cv, true)
    })

    return packs
  }, [searchablePacks.data, filter.value])

  const sortablePacks = useSortable(filterablePacks, [sort.by], [sort.order])

  const resetFilter = useCallback(() => filter.onChange(initialFilter), [filter, initialFilter])

  return (
    <Content className="pt-8 px-2">
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          <span className="pt-4 text-lg text-primary-blue">Packs</span>
        </div>
        <div className="flex-1 pr-4">
          <Button onClick={() => addPackSheetVisible.onChange(true)} className="float-right">
            Add Pack
            <PlusCircleOutlined />
          </Button>
        </div>
      </div>

      <Toolbar actions={[<SortButton options={{ name: 'Name' }} onChange={(by, order) => setSort({ by, order })} />]}>
        <Input.Search placeholder="Search packs" {...searchablePacks.input} />
      </Toolbar>

      <div className="flex items-center px-4 pt-2">
        <span className="mr-1">Filters:</span>
        <Button
          icon={filter.value.touched ? <EditOutlined /> : <PlusOutlined />}
          onClick={() => setFilterSheetVisible(!filterSheetVisible)}
        />
        {filter.value.touched && <Button className="ml-2" icon={<CloseOutlined />} onClick={resetFilter} />}
      </div>

      <ScrollView className="p-4">
        {sortablePacks.map(pack => (
          <Link key={`pack-${pack.id}`} to={`/packs/${pack.id}`}>
            <PackCard pack={pack} />
          </Link>
        ))}
      </ScrollView>
      <BottomSheet {...addPackSheetVisible}>
        <CreatePackForm onCreate={() => addPackSheetVisible.onChange(false)} />
      </BottomSheet>
      <PackFilter visible={filterSheetVisible} onCancel={() => setFilterSheetVisible(false)} {...filter} />
    </Content>
  )
}

export const CreatePackForm: React.FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const thunkDispatch = useThunkDispatch()
  const academy = useAcademy() as Academy
  const [inFlight, setInFlight] = useState(false)

  const handleOnFinish = async (values: any) => {
    try {
      setInFlight(true)
      await thunkDispatch(createPack(academy.id, values))
      message.success('Pack created successfully!')
      onCreate()
    } catch {
      message.error('Failed to create!')
    } finally {
      setInFlight(false)
    }
  }

  const [valueType, setValueType] = useState('Months')
  const [formCreatePack] = Form.useForm()
  const handleTypeChange = (value: PackType) => {
    if (value === PackType.PAY_PER_CLASS) {
      setValueType('Classes')
    } else if (value === PackType.PERIODIC) {
      setValueType('Months')
    }
  }

  return (
    <Form
      form={formCreatePack}
      onFinish={handleOnFinish}
      className="m-4 text-white"
      initialValues={{
        type: PackType.PERIODIC
      }}
    >
      <Form.Item className="mb-2" label="Name" name="name" rules={[{ required: true, message: 'Please enter name!' }]}>
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        className="mb-2"
        label="Pack type"
        name="type"
        rules={[{ required: true, message: 'Please select type!' }]}
      >
        <Select placeholder="Select type" onChange={handleTypeChange}>
          <Select.Option value={PackType.PERIODIC}>Periodic</Select.Option>
          <Select.Option value={PackType.PAY_PER_CLASS}>Pay per class</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        className="mb-2"
        label={`Value (${valueType.toLowerCase()})`}
        name="value"
        getValueFromEvent={event => parseInt(event.target.value, 10) || event.target.value}
        rules={[
          { required: true, message: 'Please enter value!' },
          { type: 'integer', message: 'Enter a numeric value' }
        ]}
      >
        <Input placeholder="Value" />
      </Form.Item>

      <Form.Item
        className="mb-2"
        label="Price"
        name="price"
        getValueFromEvent={event => parseInt(event.target.value, 10) || event.target.value}
        rules={[
          { required: true, message: 'Please enter Price!' },
          { type: 'integer', message: 'Enter a numeric value' }
        ]}
      >
        <Input placeholder="Price" />
      </Form.Item>

      <Form.Item>
        <div className="mt-4">
          <CAButton type="transparent" text="Save" loading={inFlight} onClick={() => formCreatePack.submit} />
        </div>
      </Form.Item>
    </Form>
  )
}

export default Packs
