import * as React from 'react'

function SvgStudents(props) {
  return (
    <svg id="students_svg__Layer_1" x={0} y={0} viewBox="0 0 337.6 257.4" xmlSpace="preserve" {...props}>
      <style />
      <path d="M124.5 185.4c43.8 0 79.5-35.7 79.5-79.5s-35.7-79.5-79.5-79.5S45 62.1 45 105.9s35.7 79.5 79.5 79.5zm0-144c35.6 0 64.5 28.9 64.5 64.5s-28.9 64.5-64.5 64.5S60 141.5 60 105.9s28.9-64.5 64.5-64.5z" />
      <path d="M214.3 154.8c-2.9-3-7.6-3.1-10.6-.2s-3.1 7.6-.2 10.6c19.7 20.5 30.5 47.4 30.5 75.8v1.5H15V241c0-28.4 10.8-55.3 30.5-75.8 2.9-3 2.8-7.7-.2-10.6s-7.7-2.8-10.6.2C12.3 178 0 208.7 0 240.9c0 2.4.1 4.8.2 7.2-.1.6-.2 1.2-.2 1.8 0 4.1 3.4 7.5 7.5 7.5h234c4.1 0 7.5-3.4 7.5-7.5 0-.6-.1-1.2-.2-1.8.1-2.4.2-4.8.2-7.2 0-32.2-12.3-62.9-34.7-86.1zM215.7 18.1c5.7-2 11.6-3.1 17.7-3.1 28.7 0 52 23.3 52 52s-23.3 52-52 52c-3.3 0-6.6-.3-9.8-.9l-2.8 14.7c4.1.8 8.4 1.2 12.7 1.2 37 0 67-30.1 67-67 0-37-30.1-67-67-67-7.8 0-15.5 1.3-22.8 4-12 4.4-22.6 12.1-30.4 22.3l11.9 9.1c6-8 14.1-13.9 23.5-17.3zM337.6 178.7c0-27-10.3-52.7-29-72.2-2.9-3-7.6-3.1-10.6-.2s-3.1 7.6-.2 10.6c16 16.7 24.8 38.6 24.8 61.7h-64.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5h72c4.1 0 7.5-3.4 7.5-7.5 0-.5-.1-1.1-.2-1.6.2-1.9.2-3.9.2-5.8z" />
    </svg>
  )
}

export default SvgStudents
