import React from 'react'
import BottomNav, { NavLink } from './BottomNav'
import Content, { ScrollView } from './Content'

const WithBottomNav: React.FC = ({ children }) => {
  const links: NavLink[] = [
    {
      title: 'Classes',
      path: '/classes',
      icon: 'class'
    },
    {
      title: 'Coaches',
      path: '/coaches',
      icon: 'coaches'
    },
    {
      title: 'Students',
      path: '/students',
      icon: 'students'
    },
    {
      title: 'Billing',
      path: '/billing/invoices',
      icon: 'billing'
    },
    // {
    //   title: 'Home',
    //   path: '/',
    //   icon: 'home'
    // },
    {
      title: 'Packs',
      path: '/packs',
      icon: 'bundle-pack'
    },
    {
      title: 'Profile',
      path: '/me',
      icon: 'user'
    },
    {
      title: 'Logout',
      path: '/logout',
      icon: 'logout'
    }
  ]

  return (
    <Content>
      <ScrollView>{children}</ScrollView>
      <BottomNav links={links} />
    </Content>
  )
}

export default WithBottomNav
