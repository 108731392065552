import React, { useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/store'
import Content, { ScrollView } from '../../components/Content'
import StudentsGroupsNav from '../../components/students/StudentsGroupsNav'
import StudentList from '../../components/StudentList'
import CreateStudentSheet from '../../components/students/CreateStudentSheet'

const Students: React.FC = () => {
  const students = useSelector((state: AppState) => state.students.data)

  const [addStudentSheetVisible, setAddStudentSheetVisible] = useState(false)

  const actions = [
    <Button
      key="students-add"
      className="ml-1"
      icon={<PlusCircleOutlined />}
      onClick={() => setAddStudentSheetVisible(true)}
    />
  ]

  return (
    <Content className="pt-8 px-2">
      {/* <SidePanel visible={visibleDrawer} onClose={closeDrawer} /> */}
      <div className="flex pl-4">
        <div className="flex-1 text-overflow text-sm border-b">
          {/* <CAIcon type="menu" height="10px" onClick={openDrawer} /> */}
          <span className="pt-4 text-lg text-primary-blue">
            Students <span className="text-sm text-primary-grey">/ Individual</span>
          </span>
        </div>
      </div>
      <StudentsGroupsNav />
      <ScrollView>
        <StudentList data={students} actions={actions} />
      </ScrollView>
      <CreateStudentSheet visible={addStudentSheetVisible} onCancel={() => setAddStudentSheetVisible(false)} />
    </Content>
  )
}

export default Students
