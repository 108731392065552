import axios from 'axios'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_CORE_URL_V3
})

export const updateAxiosAuthHeader = (accessToken: string) => {
  axiosClient.defaults.headers = { authorization: accessToken }
}

export const updateAxiosAcademyId = (academyId: string) => {
  axiosClient.interceptors.request.use(config => {
    config.params = {
      academyId
    }
    return config
  })
}

export default axiosClient
