import * as React from 'react'

function SvgClass(props) {
  return (
    <svg id="class_svg__Layer_1" x={0} y={0} viewBox="0 0 185.2 233.9" xmlSpace="preserve" {...props}>
      <style />
      <path d="M169.1 81.8h-153c-4.1 0-7.5 3.4-7.5 7.5v76.2c0 12.1 9.8 21.9 21.9 21.9h53.8v19.2c-4.3 2.6-7.2 7.3-7.2 12.6 0 8.1 6.6 14.7 14.7 14.7s14.7-6.6 14.7-14.7c0-5.4-2.9-10.1-7.2-12.6v-19.2h55.4c12.1 0 21.9-9.8 21.9-21.9V89.3c0-4.1-3.4-7.5-7.5-7.5zm-7.5 83.7c0 3.8-3.1 6.9-6.9 6.9H30.5c-3.8 0-6.9-3.1-6.9-6.9V96.8h138v68.7zM165.8 27h-34.6C126.6 11.2 112 0 95 0 78 0 63.5 11.2 58.8 27H19.4C8.7 27 0 35.7 0 46.4v24.1C0 74.6 3.4 78 7.5 78h170.2c4.1 0 7.5-3.4 7.5-7.5V46.4c0-10.7-8.7-19.4-19.4-19.4zM95 15c8.6 0 16.2 4.8 20 12H75c3.8-7.2 11.4-12 20-12zm75.2 48H15V46.4c0-2.5 2-4.4 4.4-4.4h146.3c2.4 0 4.4 2 4.4 4.4V63z" />
      <path d="M43.5 132h57.3c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5H43.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5zM43.5 160.4h21.3c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5H43.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5z" />
    </svg>
  )
}

export default SvgClass
