import DataStatus from '../types/ActionState'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../store/store'

const useDataStatus = (selector: (state: AppState) => DataStatus, onChange: (successful: boolean) => void) => {
  const status = useSelector(selector)

  useEffect(() => {
    if (status !== DataStatus.LOADING) {
      if (status === DataStatus.SUCCESS) {
        onChange(true)
      } else if (status === DataStatus.ERROR) {
        onChange(false)
      }
    }
  }, [onChange, status])

  return status
}

export default useDataStatus
