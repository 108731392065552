import React, { useCallback } from 'react'
import { UserOutlined, DeleteOutlined } from '@ant-design/icons'
import Coach from '../../types/Coach'
import { Divider, message } from 'antd'
import { useThunkDispatch } from '../../store/store'
import { deleteCoach } from '../../store/thunks'

interface Props {
  coach: Coach
}

const CoachCard: React.FC<Props> = props => {
  const { uuid, fullName, username, studentCount } = props.coach
  const dispatch = useThunkDispatch()

  const handleOnDelete = useCallback(
    async event => {
      event.stopPropagation()
      event.preventDefault()
      try {
        await dispatch(deleteCoach(uuid))
        message.success('Coach deleted successfully')
      } catch (ex) {
        message.error('Error while deleting coach')
      }
    },
    [dispatch, uuid]
  )

  return (
    <div className="flex justify-between bg-gradient-primary text-white mb-4 p-4 rounded-lg">
      <div className="flex items-center">
        <UserOutlined className="pt-1 text-xl" />
        <div className="ml-2">
          {fullName} / <span className="text-sm">{username}</span>
        </div>
      </div>
      <div className="flex items-center flex-col">
        <div className="text-2xl">{studentCount}</div>
        <div className="text-xs">students</div>
      </div>
      <>
        <Divider type="vertical" className="ml-2 h-6 self-center" />
        <DeleteOutlined className="text-lg self-center" onClick={handleOnDelete} />
      </>
    </div>
  )
}

export default CoachCard
