import User from '../types/User'

export function formatUserName(user: User) {
  return `${fullUserName(user)} (${user.username})`
}

export function fullUserName(user: User) {
  return `${user.firstname}, ${user.lastname}`
}

export function userWithInfo(user: User) {
  const t = user.username.split('_')
  return {
    ...user,
    formattedName: formatUserName(user),
    fullName: fullUserName(user),
    serialNo: parseInt(t[t.length - 1], 10) || Number.MAX_SAFE_INTEGER
  }
}
