import React, { useState } from 'react'
import '@ant-design/compatible/assets/index.css'
import { Input, Form, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { login } from '../store/thunks'
import { useThunkDispatch } from '../store/store'
import CAButton from '../components/Chessadmin/Button'

const Login: React.FC = props => {
  return (
    <div className="flex flex-col h-screen justify-between">
      <div className="flex flex-col">
        <p className="text-3xl leading-none px-8 pt-16 text-primary-blue">Login</p>
        <div className="mx-8">
          <LoginFrom />
        </div>
      </div>
    </div>
  )
}

const LoginFrom = () => {
  const dispatch = useThunkDispatch()
  const history = useHistory()
  const [inFlight, setInFlight] = useState(false)

  const handleOnFinish = ({ username, password }: any) => {
    setInFlight(true)
    dispatch(login(username, password))
      .then(() => {
        setInFlight(false)
        history.replace('/')
      })
      .catch(() => {
        setInFlight(false)
        message.error('Failed to login.')
      })
  }
  const [formLogin] = Form.useForm()
  return (
    <Form form={formLogin} onFinish={handleOnFinish}>
      <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
        <Input placeholder="Username" />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
        <Input type="password" placeholder="Password" />
      </Form.Item>
      <Form.Item className="pt-4">
        <CAButton text="Login" type="primary" onClick={() => formLogin.submit} loading={inFlight} />
        <div className="mt-2">
          <CAButton text="Or Signup" type="transparent" onClick={() => history.push('/signup')} />
        </div>
        {/* <div className="text-center">
          Or <Link to="/signup">signup now!</Link>
        </div> */}
      </Form.Item>
    </Form>
  )
}

export default Login
